export const h = {
  "语言": "العربية",
  "首页": "الصفحة الرئيسية",
  "产品中心": "مركز المنتجات",
  "服务支持": "مركز خدمات",
  "新闻资讯": "أخبار",
  "关于我们": "معلومات عنا",
  "联络我们": "ابقى على تواصل",
  "联系我们": "اتصل بنا ",
  "隐私政策": "سياسة الخصوصية",
  "使用条款": "شروط الاستخدام",
  "辅助功能": "إمكانية الوصول",
  "查看产品": "عرض المنتجات",
  "全球业务": "خدمات الاعمال العالمية",
  "阅读更多": "اقرأ المزيد",
  "招聘": "التوظيف",
  "招聘岗位": "الوظائف",
  "2024年6月26日": "٢٦ يونيو ٢٠٢٤",
  "人才招聘": "التوظيف",
  "了解我们的产品": "تعرف على منتجاتنا",
  "了解我们的企业": "تعرف على أعمالنا ",
  "了解更多": "التعرف على المزيد",
  "产品服务": "خدمة المنتج",
  "售后服务地址": "عنوان خدمة ما بعد البيع",
  "售后服务热线": "اتصال مباشر لخدمة ما بعد البيع",
  "售后服务": "خدمة ما بعد البيع ",
  "应急电源车": "مركبة طاقة للطوارئ",
  "通信指挥车": "مركبة قيادة الاتصالات",
  "应急通信车": "مركبة اتصالات للطوارئ",
  "消防车": "شاحنة الإطفاء",
  "救护车": "سيارة إسعاف",
  "拖车": "مقطورة",
  "房车": "عربة سكن متنقلة",
  "中型基站车": "شاحنة محطة قاعدية متوسطة",
  "现代奴隶制声明": "بيان العبودية الحديثة",
  "提交联系方式": "إدخال بينات الاتصال",
  "特种车辆改装": "تعديل خاص للمركبات",
  "先进电能源系统": "نظام الطاقة الكهربائية المتقدم",
  "超静音电站": "محطة كهرباء فائقة الصمت",
  "超静音电站分类": "تصنيف محطة الطاقة فائقة الصمت",
  "UCSS系列": "UCSS سلسلة ",
  "CSS系列": "CSS سلسلة",
  "静音电站": "محطة الطاقة صامتة",
  "静音电站分类": "تصنيف محطة الطاقة الصامتة",
  "通用电站": "محطة الطاقة العامة",
  "通用电站分类": "تصنيف محطة الطاقة العامة ",
  "军用电站": "محطة الطاقة العسكرية",
  "军用电站分类": "تصنيف محطة الطاقة العسكرية",
  "船用电站": "محطة الطاقة البحرية",
  "船用电站分类": "تصنيف محطة الطاقة البحرية",
  "发动机系列电站": "محطات طاقة سلسة المحرك",
  "发动机系列电站分类": "تصنيف محطة طاقة سلسة المحركات",
  "帕金斯动力": "قوة بيركنز",
  "博杜安动力": "قوة بودوين",
  "康明斯动力": "قوة كمنز",
  "沃尔沃动力": "قوة فولفو",
  "上柴动力": "قوة شانغتشاي",
  "玉柴动力": "قوة يوشاي",
  "多源电站": "محطات الطاقة متعددة المصادر",
  "多源电站分类": "تصنيف محطة الطاقة متعددة المصادر",
  "取力发电系统": "نظام توليد الطاقة",
  "KLK特点": "KLKميزات",
  "驻车取力发电系统": "نظام توليد الطاقة لوقوف السيارات",
  "行车取力发电系统": "نظام توليد الطاقة القيادة",
  "查看参数": "عرض البارامترات",
  "产品特点": "مميزات المنتج",
  "产品参数": "بارامترات المنتج",
  "服务中心": "مركز الخدمات",
  "了解我们": "من نحن",
  "暂停": "توقف",
  "播放": "بدء",
  "型号": "رقم الموديل",
  "功率": "كيلو وات",
  "电压": "فولت",
  "噪音": "الصوت",
  "主机参数": "بارامترات المحرك الرئيسي",
  "散热器参数": "بارامترات المبرد",
  "外形尺寸": "الحجم",
  "重量": "الوزن",
  "主用功率": "الطاقة الرئيسية",
  "备用功率": "الطاقة الاحتياطية",
  "发动机型号": "رقم موديل المحرك",
  "开架型": "نوع مفتوح",
  "静音型": "نوع صامت",
  "电子调速": "تحكم إلكتروني في السرعة",
  "机械调速": "التحكم في السرعة الميكانيكية",
  "调速": "التحكم في السرعة",
  "国三排放": "الانبعاثات الوطنية الثالثة",
  "高压共轨": "ارتفاع ضغط السكك الحديدية المشتركة",
  "控制单元外形尺寸": "أبعاد وحدة التحكم",
  "控制单元重量": "وزن وحدة التحكم",
  "发电机重量": "وزن المولد",
  "转速范围": "نطاق الدوران",
  "公司用因业务发展现，寻找与纵不同的你。给自己一个机会，创造属于自己的舞台。": "بسبب تطور الأعمال، تبحث الشركة عن أشخاص مختلفين عن الآخرين. امنح نفسك فرصة لإنشاء سيرتك الذاتية.",
  "中奕和创（香港）发展工业有限公司（愿景动力）是中奕和创（香港）": "شركة هونغ كونغ إس يونيتي ديفلو بمنت للصناعة (رؤية الطاقة) شركة تابعة لشركة تشونغيي هيتشوانغ (هونج كونج)",
  "发展有限公司于2023年在沙特注册成立的一家拥有军用和民用许可的": "تم تسجيل شركة التطوير المحدودة في المملكة العربية السعودية عام 2023 كشركة برخص عسكرية ومدنية",
  "本土化高端装备制造企业。": "مؤسسة محلية لتصنيع المعدات المتطورة",
  "探索更多": "استكشاف المزيد",
  "特种车辆": "مركبات خاصة",
  "特种车辆主要包括：应急电源车、冷藏车、通信指挥车、消防车、救护车和拖车等，提供更快捷、更高效的服务。": "تشمل المركبات الخاصة بشكل أساسي: مركبات إمداد الطاقة في حالات الطوارئ، والشاحنات المبردة، ومركبات قيادة الاتصالات، وسيارات الإطفاء، وسيارات الإسعاف والمقطورات، وما إلى ذلك، مما يوفر خدمات أسرع وأكثر كفاءة",
  "发电设备": "معدات توليد الطاقة",
  "发电设备主要包括：超静音电站、静音电站、特种电站、船用电站，发动机系统电站和多源电站。": "تشمل معدات توليد الطاقة بشكل أساسي: محطات الطاقة فائقة الهدوء، ومحطات الطاقة الصامتة، ومحطات الطاقة الخاصة، ومحطات الطاقة البحرية، ومحطات طاقة نظام المحرك، ومحطات الطاقة متعددة المصادر.",
  "我们的全球业务": "أعمالنا العالمية ",
  "开创特种车辆改装和发电设备生产行业的革新业务": "الابتكار في إنتاج تعديلات خاصة على المركبات ومحطات توليد الطاقة الابتكار في إنتاج تعديلات خاصة على المركبات ومحطات توليد الطاقة",
  "我们的售后服务": "خدمة ما بعد البيع",
  "坚持质量为本，服务至上，顾客满意的原则。": "الالتزام بمبدأ الجودة القائمة والموجهة نحو الخدمة",
  "发现更多": "استكشف المزيد",
  "阅读详情": ":اقرأ المزيد",
  "江苏中奕和创智能科技有限公司始于2018年，注册资金5000万元，是以研制生产静音电站、取力发电、特种电机、舰船电站、新能源发电等系列产品，产品的主要特点是“人无我有，人有我优”。": "تأسست شركة جيانغسو تشونغ يى هيتشوانغ للتكنولوجيا الذكية المحدودة في عام ٢٠١٨ برأس مال مسجل قدره ٥٠ مليون يوان، تقوم بتطوير وإنتاج سلسلة من المنتجات مثل محطات الطاقة الصامتة، وتوليد الطاقة، والمحركات الخاصة، ومحطات طاقة السفن، وتوليد الطاقة الجديدة، وما إلى ذلك. والسمات الرئيسية للمنتجات هى \"لدينا عندما لا يمتلكها الآخرون ولديينا أفضل منها عندما يمتلكها الآخرون",
  "电源类主要有航空交直流电源车、航空蓄电池充电车、航空蓄电池启动车、自行式应急发电车等产品，可满足飞机起动和通电检查，蓄电池充电及应急供电的保障需求。": "تشمل فئة الطاقة بشكل أساسي مركبة طاقة ACوDC الهوائية، ومركبة شحن بطاريات الطيران، ومركبة بدء تشغيل بطارية الطيران، ومركبة توليد الطاقة ذاتية الدفع في حالات الطوارئ وغيرها من المنتجات، ويمكن أن تلبي احتياجات تشغيل الطائرة وفحص الطاقة، وشحن البطاريات، وحماية إمدادات الطاقة في حالات الطوارئ.",
  "通信指挥车是一种集成了多种通信手段和指挥、调度、会商、办公、保障等功能的特种车辆。": "مركبة قيادة الاتصالات هي مركبة خاصة تدمج مجموعة متنوعة من وسائل ووظائف الاتصال مثل القيادة والإرسال والاستشارة والمكتب والدعم.",
  "应急通信车主要是提供快速、及时的应急救助服务，为社会公共安全提供强有力的保障。": "توفر مركبات اتصالات الطوارئ بشكل أساسي خدمات إنقاذ الطوارئ السريعة وفي الوقت المناسب وتوفر ضمانًا قويًا للسلامة الاجتماعية والعامة.",
  "消防车主要是用于救火或其它紧急抢救用途的车辆。": "سيارات الإطفاء هي مركبات تستخدم لمكافحة الحرائق أو غيرها من أغراض الإنقاذ في حالات الطوارئ.",
  "救护车主要是用于在紧急情况下，如事故、疾病等，快速将伤病者送往医院进行救治。": "وتُستخدم سيارات الإسعاف لنقل المرضى والمصابين بسرعة إلى المستشفى لتلقي العلاج في الحالات الطارئة، مثل الحوادث والأمراض وغيرها",
  "多功能拖车主要是用途提供动力电源，照明和调节环境温度。": "تُستخدم المقطورات متعددة الوظائف بشكل أساسي لتوفير الطاقة والإضاءة وتنظيم درجة الحرارة المحيطة.",
  "房车是一种具有居家必备的基本设施的车辆。": "عربة سكن متنقلة هي مركبة تحتوي على المرافق الأساسية اللازمة للمنزل.",
  "主要由柴油水冷发动机、水冷励磁发电机、分体式散热器、静音箱罩、远控面板、水冷阻抗复合消音器、水冷排气管、外置蓄电池及其他辅助设备组成。": "يتكون بشكل أساسي من محرك مبرد بالماء يعمل بالديزل، ومولد إثارة مبرد بالماء، ومبرد من النوع المقسم، وغطاء صندوق صامت، ولوحة تحكم عن بعد، وكاتم للصوت مركب للمعاوقة المبردة بالماء، وأنابيب عادم مبردة بالماء، وبطارية خارجية، ومعدات مساعدة أخرى.",
  "全水冷单循环技术，具有操作简单、尺寸小、重量轻、噪音低的优点。": "تتميز تقنية الدورة الواحدة المبردة بالماء بالكامل بمزايا التشغيل البسيط والحجم الصغير والوزن الخفيف وصوت منخفض.",
  "主要由柴油水冷发动机、水冷盘式发电机、逆变器、分体式散热器、静音箱罩、远控面板、阻抗复合水冷消音器、水冷排气歧管、外置蓄电池及其他辅助设备组成。": "يتكون بشكل أساسي من محرك ديزل مبرد بالماء، ومولد قرص مبرد بالماء، وعاكس، ومبرد منقسم، وغطاء صندوق صامت، ولوحة تحكم عن بعد، وكاتم صوت مركب ممانعة مبرد بالماء، ومجمع عادم مبرد بالماء وبطارية خارجية وغيرها من المعدات المساعدة.",
  "水冷+风冷散热系统，环境适应性强，尺寸小、重量轻、功率密度高。": "نظام تبريد مبرد بالماء + الهواء المبرد، قدرة قوية على التكيف البيئي، الحجم الصغير، الوزن الخفيف، وكثافة الطاقة العالية.",
  "中型基站车通过装载的专用传输设备，可以提供语音、短信、数据及视频等信息互通。": "يمكن لمركبات المحطة الأساسية متوسطة الحجم توفير تبادل المعلومات مثل الصوت والرسائل النصية والبيانات والفيديو من خلال معدات الإرسال المخصصة.",
  "标配内置蓄电池和油箱，易操作、静音、防雨，可定制化生产、价格实惠。": "تأتي بشكل قياسي مع بطارية مدمجة وخزان وقود، وهو سهل التشغيل، وصامت، ومقاوم للمطر. ويمكن تخصيصه وإنتاجه بسعر مناسب. ",
  "主要由柴油水冷发动机、风冷励磁发电机、散热器、静音箱罩、远控面板、阻抗复合消音器、低温蓄电池及其他辅助设备组成。": "إنه يتكون بشكل أساسي من محرك ديزل مبرد بالماء، ومولد مثير مبرد بالهواء، ومبرد، وغطاء صندوق صامت، ولوحة تحكم عن بعد، وكاتم صوت مركب المعاوقة، وبطارية منخفضة الحرارة وغيرها من المعدات المساعدة.",
  "可定制化生产、通用型、紧凑型、隐蔽型。一体化结构设计，体积小、重量轻，布局紧凑。": "إنتاج قابل للتخصيص، عالمي، مدمج ومخفي. التصميم الهيكلي المتكامل، الحجم الصغير، الوزن الخفيف والتصميم المدمج.",
  "低噪音，低排放，低油耗，全球联保，维修方便；": "الصوت منخفض، انبعاثات منخفض، استهلاك منخفض للوقود، ضمان عالمي، سهولة الصيانة،",
  "功率(1500RPM/50HZ):70KW-400KW(88KVA-500KVA)；": "الطاقة (١٥٠٠ دورة في الدقيقة/٥٠ هرتز): ٧٠ كيلو واط - ٤٠٠ كيلو واط (٨٨ كيلو فولت أمبير - ٥٠٠ كيلو فولت أمبير)، ",
  "垣福电机，利莱森玛、马拉松、西门子、康富、孚日可选；": "محركات يوانفو إلكتريك و ليروي سومر و ماراثون و سيمنز و كانغفو وفوج اختيارية،التشغيل الكهربائي، التشغيل الربيعي، والبدء الهوائي اختيارية؛ إن شاشة مراقبة محرك الديزل ولوحة التحكم في مجموعة المولدات اختيارية.",
  "主要适用于电信、体育赛事、小型至大型工厂、灯塔、混合能源。": "مناسبة بشكل أساسي للاتصالات السلكية واللاسلكية والأحداث الرياضية والمصانع الصغيرة والكبيرة والمنارات والطاقة الهجينة.",
  "主要适用于矿山、电站、农场、石油天然气、租赁电源、高空应用、极热地区应用、体育赛事、大中型工厂。": "مناسبة بشكل أساسي للتعدين ومحطات الطاقة والمزارع والنفط والغاز وإمدادات الطاقة المستأجرة والتطبيقات على ارتفاعات عالية والتطبيقات في المناطق شديدة الحرارة والأحداث الرياضية والمصانع الكبيرة والمتوسطة الحجم.",
  "主要适用于矿山、电站、农场、石油天然气、港口及近海、公路或越野移动电源(电源卡车)、租赁电源、重型建筑设备、高空应用、极热区域应用、极冷区域应用、泵应用、体育赛事、中小型工厂。": "مناسبة للتعدين ومحطات الطاقة والمزارع والنفط والغاز والموانئ وإمدادات الطاقة المتنقلة على الطرق أو الطرق الوعرة (شاحنات الطاقة) وإمدادات الطاقة المستأجرة ومعدات البناء الثقيلة والتطبيقات على ارتفاعات عالية وتطبيقات المناطق الساخنة الشديدة ، تطبيقات المناطق شديدة البرودة، تطبيقات المضخات، المسابقات الرياضية، المصانع الصغيرة والمتوسطة.",
  "光-油-储一体机微电站系列通过集成太阳能、储能电池系统、柴油发电机组和逆变器组成。": "تتكون سلسلة محطات الطاقة الصغيرة المتكاملة لتخزين الزيت الخفيف من الطاقة الشمسية المتكاملة ونظام بطارية تخزين الطاقة ومجموعة مولدات الديزل والعاكس.",
  "发电机是利用原车冗余空间及发动机原有螺接孔位进行安装；": "يتم تركيب المولد باستخدام المساحة الزائدة للمركبة الأصلية وفتحات المسامير الأصلية للمحرك،",
  "不需要新增开孔，不占用车辆上装空间，对车辆性能无影响；": "ليست هناك حاجة لفتحات جديدة، ولا تشغل مساحة هيكل السيارة، وليس لها أي تأثير على أداء السيارة",
  "发电机体积小，重量轻，发电效率高，发热低；": "المولد صغير الحجم، خفيف الوزن، ذو كفاءة عالية في توليد الطاقة وتوليد حرارة منخفضة، ",
  "巧妙利用车辆空间，不影响整车性能；": "الاستفادة بذكاء من مساحة السيارة دون التأثير على أداء المركبة، ",
  "低速满功率输出，供电稳定；": "انخفاض سرعة انتاج الطاقة الكاملة، وإمدادات الطاقة مستقرة.",
  "电机水冷结构设计，防护等级可达IP57；": "تصميم هيكل تبريد الماء للمحرك، مستوى حماية يصل إلىIP٥٧،",
  "采用高频PWM和软开关变换技术，整机效率高；": "باستخدام PWM عالي التردد وتكنولوجيا تحويل التبديل الناعم، تكون كفاءة الماكينة الإجمالية عالية،",
  "解决动中通连续刹车情况下供电不稳定的难题；": "حل مشكلة عدم استقرار إمدادات الطاقة في حالة الكبح المستمر ،",
  "选用盘式水冷电机，长度较普通电机较少30-60cm，重量减少50-90kg；": "باستخدام محرك مبرد بالماء من نوع القرص، يكون الطول أقصر بمقدار ٣٠-٦٠ سم من المحركات العادية، ويتم تقليل الوزن بمقدار ٥٠-٩٠ كجم،",
  "单侧检修门，更人性化的设计，便于日常维修保养；": "باب من جانب واحد، تصميم أكثر سهولة في الاستخدام، مناسب للصيانة اليومية،",
  "全封闭式隔音罩设计，静音效果好；": "تصميم غطاء عازل للصوت مغلق بالكامل، تأثير كتم الصوت جيد،",
  "采用二级减震系统，减震效果好；": "باستخدام نظام امتصاص الصدمات بمستويين، فإن تأثير امتصاص الصدمات جيد،",
  "电气性能指标优异；": "مؤشرات الأداء الكهربائي ممتازة،",
  "尺寸小、重量轻、功率密度高；": "حجم صغير، خفيف الوزن، كثافة طاقة عالية،",
  "水冷励磁电机，稳定性更高；": "محرك الإثارة المبرد بالماء لديه ثبات أعلى،",
  "短轴风冷励磁电机，尺寸更小，重量更轻；": "محرك إثارة مبرد بالهواء قصير المحور، أصغر حجمًا وأخف وزنًا،",
  "半封闭式隔音罩设计，静音效果好；": "تصميم غطاء عازل للصوت شبه مغلق، تأثير كتم الصوت جيد،",
  "低噪声、低振动、低红外特征；": "انخفاض مستوى الصوت، وانخفاض الاهتزاز، وانخفاض خصائص الأشعة تحت الحمراء،",
  "智能化控制（本地、远程双启动控制面板）；": "التحكم الذكي (لوحة التحكم المحلية والبعيدة ذات التشغيل المزدوج)،",
  "可定制化生产；": "إنتاج قابل للتخصيص،",
  "电启动、弹簧启动、空气启动可选；": "قفزة البدء ،مولد الربيع معدات وبدء تشغيل الهواء اختيارية،",
  "柴油机监控仪、发电机组控制屏可选；": "شاشة محرك الديزل، شاشة التحكم في مجموعة المولدات اختيارية،",
  "采用高频PWM 和软开关变换技术，整机效率高；": "باستخدام PWM عالي التردد وتكنولوجيا تحويل التبديل الناعم، تكون كفاءة الماكينة الإجمالية عالية",
  "体积小、重量轻、环境适应能力强；": "حجم صغير وخفيف الوزن وقدرة قوية على التكيف البيئي،",
  "一体式设计，免安装，运输便利、操作简单，维护方便，高效美观，可根据需求定制，适用于多种场景。": "تصميم متكامل، خالي من التركيب، نقل مريح، تشغيل بسيط، سهولة الصيانة، فعال وجميل، يمكن تخصيصه وفقًا للاحتياجات، وهو مناسب لمجموعة متنوعة من السيناريوهات.",
  "输出电压可控，低速满功率输出，供电稳定；": "يمكن التحكم في جهد الخرج، وإخراج طاقة كامل منخفض السرعة، وإمدادات طاقة مستقرة،",
  "钛酸锂电池系统提供电量1。9kWh，满足系统自放电3min；": "يوفر نظام بطارية تيتانات الليثيوم ١.٩ كيلو واط في الساعة من الطاقة، والتي يمكن أن تلبي التفريغ الذاتي للنظام لمدة ٣ دقائق،",
  "结构紧凑、轻便、体积小、重量轻、环境适应能力强；": "هيكل مدمج، صغير الحجم، خفيف الوزن، قدرة قوية على التكيف البيئي،",
  "额定电压：AC220V/380V；": "الفولطية: AC٢٢٠V/٣٨٠V،",
  "转速范围：750rpm～2300rpm；": "نطاق السرعة: ٧٥٠ دورة في الدقيقة ~ ٢٣٠٠ دورة في الدقيقة،",
  "全转速范围内可以额定功率输出；": "يمكن تحقيق خرج الطاقة المقدر ضمن نطاق السرعة الكامل،",
  "目前，公司研制、生产和售后服务能力和队伍已经建成，相应的生产厂房和设施已经到位，生产设备也已经就位，开始了生产制造和运营，整个队伍已经达到了20余人，完全具备了高端装备和车辆改装生产的资质和能力，也具备了7×24小时售后服务保障能力。": "في الوقت الحاضر، تم إنشاء قدرات وفرق البحث والتطوير والإنتاج وخدمات ما بعد البيع للشركة، كما أن مصانع ومرافق الإنتاج المقابلة موجودة، وقد بدأ الإنتاج والتصنيع والعمليات وصلت إلى أكثر من ٢٠ شخصًا، وهي مجهزة تجهيزًا كاملاً بأحدث المعدات ولديها المؤهلات والقدرات اللازمة لإنتاج المعدات وتعديلات المركبات، ولديها أيضًا القدرة على تقديم خدمة ما بعد البيع على مدار ٢٤ /٧ ساعة.",
  "公司在生产及售后服务方面均具备优秀的能力。": "تتمتع الشركة بقدرات ممتازة في الإنتاج وخدمة ما بعد البيع.",
  "全面获得高端装备及车辆改装生产的资质，并具备了高精尖的生产力。": "لقد حصلت على مؤهلات شاملة لإنتاج المعدات المتطورة وتعديلات المركبات، ولديها إنتاجية عالية الدقة.",
  "建立了完善的售后服务体系，并具备7×24小时的全方位服务保障能力。": "لقد أنشأنا نظامًا متكاملاً لخدمة ما بعد البيع ولدينا إمكانات دعم الخدمة الشاملة على مدار ٢٤ /٧ساعة. ",
  "售后服务保障地址": "عنوان ضمان خدمة ما بعد البيع",
  "中奕和创（香港）发展工业有限公司": "شركة هونغ كونغ إس يونيتي ديفلو بمنت للصناعة",
  "沙特阿拉伯阿尔塔卡索西街7767，奥拉亚区-利雅得12332。": "٧٧٦٧ شارع التقوى الغربي، حي العليا - الرياض ١٢٣٣٢، المملكة العربية السعودية.",
  "联系人": "اسم جهة الاتصال",
  "雷仕清": "لي شي تشينغ",
  "国际维保电话": "رقم الهاتف الدولي للصيانة",
  "国内维保电话": "رقم هاتف الصيانة المنزلية",
  "中奕和创（香港）发展工业有限公司（愿景动力）于2023年在沙特注册成立的一家本土化高端装备制造企业。公司集研发、制造、销售、服务为一体，公司拥有完整的专业技术队伍和完善的生产制造条件，主营业务以特种车辆改装和高端发电设备为主，立足沙特，辐射中东，服务全球，打造愿景动力，世界品牌。": ":شركة هونغ كونغ إس يونيتي ديفلو بمنت للصناعة (ويشن باوو) تأسست في المملكة العربية السعودية عام 2023 و هي شركة محلية لتصنيع المعدات الراقية ,وتجمع الشركة بين أنشطة البحث و        التطوير والتصنيع والبيع والخدمات, فالشركة لديها فريق محترف بالفنية كاملا وأحسن الظروف  للإنتاج والتصنيع,والنشاطة الرئيسي للشركه هي تعديل و تغيير المركبات الخاصة و تصنيع معدات راقية تولد الكهرباء مقيمة بالسعودية ومتوجهة شرق الأوسط وخدمة العالم وبناء ”ويشن باوو” وعلامة تجارية عالمية.",
  "公司简介": "نبذة عن الشركة",
  "企业核心价值观": "القيم الأساسية للشركة",
  "让中奕人都能感受企业以人为本的追求。让中奕人动力源源不绝，正如我们的产品⸺永不间断！": "دع شعب تشونغيى يشعرون بالسعي الموجه نحو الناس في الشركة. دع شعب تشونغيى يتمتع بقوة لا نهاية لها، تمامًا مثل منتجاتنا - لا يتوقف أبدًا!",
  "我们不管在任何情况下，都要保持年轻、热血、以及热泪盈眶！应当永远怀着敬畏之心继续向前，不忘初心，砥砺前行！": "مهما كانت الظروف، يجب أن نبقى شبابًا وعاطفين ومليئين بالدموع! يجب أن نمضي قدمًا دائمًا برهبة، وألا ننسى أبدًا نيتنا الأصلية!",
  "合作伙伴": "الشركاء",
  "公司的核心合作伙伴阵容强大，涵盖了如帕金斯、康明斯、MTU、沃尔沃、潍柴、玉柴和上柴等业内知名品牌，我们与这些合作伙伴之间建立了稳固的战略合作关系，共同推动技术创新和产业升级。": "تمتلك الشركة تشكيلة قوية من الشركاء الأساسيين، تغطي علامات تجارية مشهورة في الصناعة مثل بيركنز وكمنز وMTU وفولفو وويشاي ويوتشاي وشانغتشاي. لقد أنشأنا شراكات استراتيجية قوية مع هؤلاء الشركاء للترويج المشترك للابتكار التكنولوجي والصناعي ترقية.。",
  "企业大事记": "احداث تجارية",
  "资质荣誉": "المؤهلات والأوسمة",
  "持有专利": "شهادة براءة",
  "荣获奖项": "الجوائز",
  "认证证书": "شهادة اعتماد",
  "地址：沙特阿拉伯阿尔塔卡索西街7767，奥拉亚区-利雅得12332": "العنوان: ٧٧٦٧ شارع التقوى الغربي، حي العليا - الرياض ١٢٣٣٢، المملكة العربية السعودية",
  "财务部部长": "المدير المالي",
  "1万-1。5万": "١٥٠٠٠ - ١٠٠٠٠",
  "沙特": "سعودي",
  "5年以上": "أكثر من 5 سنوات ",
  "本科": "المرحلة الجامعية",
  "岗位描述": "المسمى الوظيفي",
  "岗位职责:": "المسؤوليات الوظيفية:",
  "主持财务部全面工作，对财务部工作效果和结果负责;建立健全公司的财务管理制度;组织编制公司预算和执行预算、决算，参与拟订资金筹措和使用方案，确保资金的有效使用;按照会计制度编制财务综合分析报告和专题分析报告，为公司领导决策提供可靠的依据，负责公司税务筹划工作，与税务、审计、银行等进行联络对接;负责公司融资(银行融资、股权融资)工作和规划，负责内控体系建设，确保公司财务运行安全。": "رئاسة العمل العام للإدارة المالية ويكون مسؤولاً عن آثار ونتائج عمل الإدارة المالية، إنشاء وتحسين نظام الإدارة المالية للشركة، تنظيم إعداد ميزانية الشركة وتنفيذ الميزانيات والحسابات الختامية، والمشاركة في ذلك صياغة خطط جمع الأموال واستخدامها، وضمان الاستخدام الفعال للأموال وفقًا للنظام المحاسبي. إعداد تقارير التحليل المالي الشاملة وتقارير التحليل الخاصة لتوفير أساس موثوق لاتخاذ القرارات القيادية في الشركة. المسؤول عن التخطيط الضريبي للشركة والاتصال بالضرائب والتدقيق والبنوك وما إلى ذلك، المسؤول عن تمويل الشركة (التمويل المصرفي، تمويل الأسهم) والعمل والتخطيط، والمسؤول عن بناء نظام الرقابة الداخلية لضمان سلامة العمليات المالية للشركة.",
  "专业技能:": "الخبرة:",
  "大学本科以上学历，财务管理、会计等相关专业毕业，具有沙特阿拉伯的财务管理、税务筹划知识，丰富的资产管理、预决算管理、投融资财务管理经验，对科技型、牛产型实体公司财务管理经验丰富。": "درجة البكالوريوس أو أعلى، تخرج من الإدارة المالية او المحاسبة او التخصصات الأخرى ذات الصلة، مع معرفة بالإدارة المالية والتخطيط الضريبي في المملكة العربية السعودية، وخبرة غنية في إدارة الأصول، وإدارة الميزانية والحساب الختامي، والإدارة المالية للاستثمار والتمويل، والإدارة المالية للتكنولوجيا -الكيانات القائمة على إنتاج الماشية والخبرة الإدارية الغنية.",
  "要求:": "المتطلبات",
  "年龄不超过45岁，男性，专业从事财务工作不少于5年，熟悉英语阿拉伯语，了解中东地区税务政策、会计法规、进出口贸易相关政策，能适应长期出差，有沙特工作经验的优先考虑。": "لا يزيد عمره عن ٤٥ عامًا، ذكرًا، خبرة في مجال التمويل لمدة ٥ سنوات على الأقل، على دراية باللغة الإنجليزية، ومعرفة بالسياسة الضريبية للشرق الأوسط، والأنظمة المحاسبية، وسياسات تجارة الاستيراد والتصدير، يمكن أن تتكيف مع السفر طويل الأجل، تجربة العمل السعودية أولوية ",
  "销售经理": "مدير المبيعات",
  "1万起": "ابتداء من ١٠٠٠٠",
  "3年以上": "أكثر من ٣ سنوات",
  "开拓新客户和维护老客户，做好客户关系运维，收集市场信息并反馈，协助销售部负责人制定部门年度经营计划，负责规定地区、规定领域的销售业务开展，完成公司下达的销售任务。": "تطوير عملاء جدد والحفاظ على العملاء القدامى، والقيام بعمل جيد في تشغيل وصيانة علاقات العملاء، وجمع معلومات السوق وإبداء الرأي، ومساعدة الشخص المسؤول عن قسم المبيعات في صياغة خطة العمل السنوية للقسم، ويكون مسؤولاً عن تطوير المبيعات الأعمال في مناطق محددة ومجالات محددة، وإكمال المهام البيعية التي تحددها الشركة.",
  "具有丰富的柴油发电机组等产品、机电一体化产品销售经验，具有很强的市场开拓精神。可熟练使用各种办公软件，可独立制作标书及投标，熟悉商务接待礼仪。": "لديه خبرة غنية في بيع مجموعات مولدات الديزل وغيرها من المنتجات، فضلا عن منتجات الميكاترونكس، ولديه روح قوية لتطوير السوق. أن يكون ماهرًا في استخدام البرامج المكتبية المختلفة، وأن يكون قادرًا على إعداد وثائق العطاءات والعطاءات بشكل مستقل، وأن يكون على دراية بآداب استقبال الأعمال.",
  "大学专科及以上学历，市场营销、机电一体化、汽车结构或机械结构相关专业毕业，年龄不超过45岁，男女不限，身体素质优良。熟悉英语或者阿拉伯语者优先。": "شهادة جامعية فما فوق، تخصص التسويق أو الميكاترونكس أو هيكل السيارات أو الهيكل الميكانيكي، ولا يزيد العمر عن ٤٥ سنة، ذكر أو أنثى، اللياقة البدنية الجيدة. سيتم إعطاء الأولوية للمتقدمين الذين لديهم معرفة باللغة الإنجليزية أو العربية.",
  "人力资源主管": "مدير الموارد البشرية",
  "1万": "١٠٠٠٠",
  "制定公司招聘、培训、薪酬体系、绩效考核等制度，并贯彻实施，根据公司制定的培训计划，配合相关部门组织人员参加培训，评估培训效果，做好新进员工的职前培训及试用期的考核考评工作等。": "صياغة وتنفيذ نظام التوظيف والتدريب والرواتب وتقييم الأداء والأنظمة الأخرى للشركة وفقًا لخطة التدريب التي صاغتها الشركة، والتعاون مع الإدارات ذات الصلة لتنظيم مشاركة الموظفين في التدريب، وتقييم تأثير التدريب، وتوفير ما قبل التوظيف. فترة التدريب والاختبار للموظفين الجدد وأعمال التقييم، وما إلى ذلك.",
  "对中东地区人力资源招聘、薪酬、绩效管理非常熟悉，对相关的法律法规非常熟悉;对相关的福利政策非常了解。": "على دراية كبيرة بتوظيف الموارد البشرية والتعويضات وإدارة الأداء في الشرق الأوسط، بالإضافة إلى القوانين واللوائح ذات الصلة ودراية كبيرة بسياسات الرعاية الاجتماعية ذات الصلة.",
  "大学本科上学历，人力资源或企业管理类相关专业毕业优先;五年以上相关工作经验，有制造行业相关工作背景者优先;在人员招募、引进、培训开发及员工考核、激励等方面有实际操作能力，熟悉国家相关劳动法律、法规，熟悉人力资源管理工作流程和运作方式;良好的计算机水平，能够熟练操作OFFICE办公软件。熟悉英语或者阿拉伯语者优先。": "يفضل الحصول على درجة البكالوريوس في الموارد البشرية أو إدارة الأعمال،أكثر من خمس سنوات من الخبرة العملية ذات الصلة، ويفضل أولئك الذين لديهم خلفية عمل متعلقة بالصناعة التحويلية في مجال توظيف الموظفين وتقديمهم وتدريبهم وتطويرهم وتقييم الموظفين والتحفيز ، إلخ. القدرة، على دراية بقوانين ولوائح العمل الوطنية ذات الصلة، وعلى دراية بسير عمل إدارة الموارد البشرية وأساليب التشغيل، ومهارات كمبيوتر جيدة، وقادر على تشغيل برامج الحاسوب بمهارة وسيتم إعطاء الأولوية للمتقدمين الذين لديهم معرفة باللغة الإنجليزية أو العربية. ",
  "联络我们，我们将尽快回答您的问题": "اتصل بنا وسوف نقوم بالرد على أسئلتك في أقرب وقت ممكن",
  "带星号（*）的字段为必填字段": "الحقول التي تحمل علامة النجمة (*) مطلوبة",
  "名": "الاسم الأول",
  "姓": "اسم العائلة ",
  "联系电话": "رقم التواصل",
  "电子邮箱": "البريد الإلكتروني",
  "公司名称": "اسم الشركة",
  "您需要那些帮助？": "ما هي المساعدة التي تحتاجها؟",
  "提交信息": "إرسال المعلومات",
  "我们的产品服务": "منتجاتنا وخدماتنا",
  "坚持质量为本，服务至上的原则": "الالتزام بمبدأ الجودة القائمة والموجهة نحو الخدمة",

  "尺寸小、重量轻、噪音低等优点":"صغيرة الحجم و خفيفة الوزن و منخفضة الصوت وغيرها من المزايا",
  "主要由柴油水冷发动机、发电机、分体式散热器、静音箱罩、远控面板、水冷阻抗复合消音器、水冷排气管、蓄电池及其他辅助设备组成。":"يتكون بشكل أساسي من محرك مبرد بالماء يعمل بالديزل، ومولد إثارة مبرد بالماء، ومبرد من النوع المقسم، وغطاء صندوق صامت، ولوحة تحكم عن بعد، وكاتم للصوت مركب للمعاوقة المبردة بالماء، وأنابيب عادم مبردة بالماء، وبطارية خارجية، ومعدات مساعدة أخرى.",
  "水冷+风冷散热系统，环境适应性强":"نظام تبديد الحرارة المبرد بالماء + الهواء المبرد، القدرة على التكيف البيئي القوي",
  "尺寸小、重量轻、功率密度高":"حجم صغير و خفيف الوزن وكثافة طاقة عالية",
  "主要由发动机、发电机、散热器、静音箱罩、控制面板，蓄电池及其他辅助设备组成。":"وهي تتكون بشكل رئيسي من المحرك و المولد والمبرد وغطاء الصندوق الصامت ولوحة التحكم والبطارية وغيرها من المعدات المساعدة.",
  "标配内置蓄电池和油箱":"يأتي بشكل قياسي مع بطارية مدمجة وخزان وقود",
  "易操作、静音、防雨":"سهل الاستخدام، صامت ومقاوم للمطر",
  "可定制化生产、价格实惠。":"إنتاج مخصص ممكن وبأسعار معقولة.",
  "可定制化生产、通用型、紧凑型、隐蔽型。":"إنتاج مخصص، عالمي، مدمج ومخفي.",
  "一体化结构设计":"التصميم الهيكلي المتكامل",
  "体积小":"حجم صغير",
  "重量轻":"وزن خفيف",
  "布局紧凑":"تصميم مدمج",
  "主要由发动机、发电机、散热器、机组底座、控制箱、消音器、蓄电池及其他辅助设备组成。":"وهي تتكون بشكل رئيسي من المحرك، المولد، المبرد، قاعدة الوحدة، صندوق التحكم، كاتم الصوت، البطارية وغيرها من المعدات المساعدة.",
  "低噪音，低排放，低油耗，全球联保，维修方便。":"الصوت منخفض، انبعاثات منخفض، استهلاك منخفض للوقود، ضمان عالمي، سهولة الصيانة",
  "功率(1500RPM/50HZ):70KW-400KW(88KVA-500KVA)。":"الطاقة (١٥٠٠ دورة في الدقيقة/٥٠ هرتز): ٧٠ كيلو واط - ٤٠٠ كيلو واط (٨٨ كيلو فولت أمبير - ٥٠٠ كيلو فولت ",
  "斯垣福电机，利莱森玛、马拉松、西门子、康富、孚日可选。":"محركات يوانفو إلكتريك و ليروي سومر و ماراثون و سيمنز و كانغفو وفوج اختيارية،التشغيل الكهربائي، التشغيل الربيعي، والبدء الهوائي اختيارية؛ إن شاشة مراقبة محرك الديزل ولوحة التحكم في مجموعة المولدات اختيارية.",
  "电启动、弹簧启动、空气启动可选":"قفزة البدء ،مولد الربيع معدات وبدء تشغيل الهواء اختيارية",
  "柴油机监控仪、发电机组控制屏可选。":"إن شاشة مراقبة محرك الديزل ولوحة التحكم في مجموعة المولدات اختيارية.",
  "主要是由发电机、控制单元、蓄电池和安装支架等组成。":"وهي تتكون بشكل رئيسي من المولد ووحدة التحكم والبطارية وقوس التثبيت.",
  "主要是由发电机、中压配电箱、供配电操控终端、传动轴和安装支架等组成":"إنها تتكون بشكل رئيسي من المولد، صندوق توزيع الجهد المتوسط، محطة التحكم في إمدادات الطاقة والتوزيع، عمود النقل وقوس التثبيت.",
  "主要是由取力发电机、电源转换器、电源管理器、储能电源、散热系统和显控盒等组成。":"وتتكون بشكل رئيسي من مولد الطاقة، محول الطاقة، مدير الطاقة، مصدر طاقة تخزين الطاقة، نظام التبريد وصندوق العرض والتحكم.",

  "暂无参数": "لا يوجد بارامترات",
  "中奕和创（香港）发展工业有限公司（愿景动力）于2023年在沙特注册成立的一家拥有军用和民用许可的本土化高端装备制造企业。": "شركة هونغ كونغ إس يونيتي ديفلو بمنت للصناعة (رؤية الطاقة) في المملكة العربية السعودية في عام ٢٠٢٣ كمؤسسة محلية لتصنيع المعدات المتطورة بتراخيص عسكرية ومدنية.",
  "全水冷单循环技术": "تقنية دورة واحدة لتبريد المياه بالكامل",
  "具有操作简单": "سهل الاستخدام",
  "尺寸小、重量轻、超静音等优点": "حجم صغير و خفيف الوزن و فائقة الصمت ومزايا أخرى.",
  "提交成功": "تم الإرسال بنجاح",
  "中奕和创(香港)发展工业有限公司": "شركة هونغ كونغ إس يونيتي ديفلو بمنت للصناعة",

  "复制成功": "تم النسخ",
  "提交失败": "الفشل",
  "不能为空": "لايمكن ان يكون فارغا",

  "立足沙特-辐射中东-服务全球": " مقيمة بالسعودية ومتوجهة شرق الأوسط وخدمة العالم.",
  "特种电站": "محطة كهرباء خاصة",

  "查看产品手册": "عرض المنتجات",
  "上一页": "الصفحة السابقة",
  "下一页": "الصفحة التالية",
  "点击下载": "اضغط للتنزيل",
  "关闭": " إغلاق",
  "申请下载产品手册pdf文件": "تقدم الطلب لتنزيل ملف بي دي اف للمنتجات",

  "公司用因业务发展，现寻找与纵不同的你。给自己一个机会，创造属于自己的舞台。": "بسبب تطور الأعمال، تبحث الشركة عن أشخاص مختلفين عن الآخرين. امنح نفسك فرصة لإنشاء سيرتك الذاتية.",
  "+966547092658": "+966 547092658",
  "+8617377355871": "+86 17377355871",

  "冷藏车": "الشاحنات المبردة",
  "主要应用卫生医疗、勤务保障、应急救援，冷链物流和房车露营等领域。": "،تستخدم بشكل رئيسي في الرعاية الطبية و دعم الخدمة والإنقاذ في حالات الطوارى و الخدمات اللوجستية لسلسلة التبريد و التخييم المتنقلة بالسيارات و غيرها",

}
