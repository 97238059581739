export const h = {
  "语言": "中文",
  "首页": "首页",
  "产品中心": "产品中心",
  "服务支持": "服务支持",
  "新闻资讯": "新闻资讯",
  "关于我们": "关于我们",
  "联络我们": "联络我们",
  "联系我们": "联系我们",
  "隐私政策": "隐私政策",
  "使用条款": "使用条款",
  "辅助功能": "辅助功能",
  "查看产品": "查看产品",
  "全球业务": "全球业务",
  "阅读更多": "阅读更多",
  "招聘": "招聘",
  "招聘岗位": "招聘岗位",
  "2024年6月26日": "2024年6月26日",
  "人才招聘": "人才招聘",
  "了解我们的产品": "了解我们的产品",
  "了解我们的企业": "了解我们的企业",
  "了解更多": "了解更多",
  "产品服务": "产品服务",
  "售后服务地址": "售后服务地址",
  "售后服务热线": "售后服务热线",
  "售后服务": "售后服务",
  "应急电源车": "应急电源车",
  "通信指挥车": "通信指挥车",
  "应急通信车": "应急通信车",
  "消防车": "消防车",
  "救护车": "救护车",
  "拖车": "拖车",
  "房车": "房车",
  "中型基站车": "中型基站车",
  "现代奴隶制声明": "现代奴隶制声明",
  "提交联系方式": "提交联系方式",
  "特种车辆改装": "特种车辆改装",
  "先进电能源系统": "先进电能源系统",
  "超静音电站": "超静音电站",
  "超静音电站分类": "超静音电站分类",
  "UCSS系列": "UCSS系列",
  "CSS系列": "CSS系列",
  "静音电站": "静音电站",
  "静音电站分类": "静音电站分类",
  "通用电站": "通用电站",
  "通用电站分类": "通用电站分类",
  "军用电站": "军用电站",
  "军用电站分类": "军用电站分类",
  "船用电站": "船用电站",
  "船用电站分类": "船用电站分类",
  "发动机系列电站": "发动机系列电站",
  "发动机系列电站分类": "发动机系列电站分类",
  "帕金斯动力": "帕金斯动力",
  "博杜安动力": "博杜安动力",
  "康明斯动力": "康明斯动力",
  "沃尔沃动力": "沃尔沃动力",
  "上柴动力": "上柴动力",
  "玉柴动力": "玉柴动力",
  "多源电站": "多源电站",
  "多源电站分类": "多源电站分类",
  "取力发电系统": "取力发电系统",
  "KLK特点": "KLK特点",
  "驻车取力发电系统": "驻车取力发电系统",
  "行车取力发电系统": "行车取力发电系统",
  "查看参数": "查看参数",
  "产品特点": "产品特点",
  "产品参数": "产品参数",
  "服务中心": "服务中心",
  "了解我们": "了解我们",
  "暂停": "暂停",
  "播放": "播放",
  "型号": "型号",
  "功率": "功率",
  "电压": "电压",
  "噪音": "噪音",
  "主机参数": "主机参数",
  "散热器参数": "散热器参数",
  "外形尺寸": "外形尺寸",
  "重量": "重量",
  "主用功率": "主用功率",
  "备用功率": "备用功率",
  "发动机型号": "发动机型号",
  "开架型": "开架型",
  "静音型": "静音型",
  "电子调速": "电子调速",
  "机械调速": "机械调速",
  "调速": "调速",
  "国三排放": "国三排放",
  "高压共轨": "高压共轨",
  "控制单元外形尺寸": "控制单元外形尺寸",
  "控制单元重量": "控制单元重量",
  "发电机重量": "发电机重量",
  "转速范围": "转速范围",
  "公司用因业务发展，现寻找与纵不同的你。给自己一个机会，创造属于自己的舞台。": "公司用因业务发展现，寻找与纵不同的你。给自己一个机会，创造属于自己的舞台。",
  "中奕和创（香港）发展工业有限公司（愿景动力）是中奕和创（香港）": "中奕和创（香港）发展工业有限公司（愿景动力）是中奕和创（香港）",
  "发展有限公司于2023年在沙特注册成立的一家拥有军用和民用许可的": "发展有限公司于2023年在沙特注册成立的一家拥有军用和民用许可的",
  "本土化高端装备制造企业。": "本土化高端装备制造企业。",
  "探索更多": "探索更多",
  "特种车辆": "特种车辆",
  "特种车辆主要包括：应急电源车、冷藏车、通信指挥车、消防车、救护车和拖车等，提供更快捷、更高效的服务。": "特种车辆主要包括：应急电源车、冷藏车、通信指挥车、消防车、救护车和拖车等，提供更快捷、更高效的服务。",
  "发电设备": "发电设备",
  "发电设备主要包括：超静音电站、静音电站、特种电站、船用电站，发动机系统电站和多源电站。": "发电设备主要包括：超静音电站、静音电站、特种电站、船用电站，发动机系统电站和多源电站。",
  "我们的全球业务": "我们的全球业务",
  "开创特种车辆改装和发电设备生产行业的革新业务": "开创特种车辆改装和发电设备生产行业的革新业务",
  "我们的售后服务": "我们的售后服务",
  "坚持质量为本，服务至上，顾客满意的原则。": "坚持质量为本，服务至上，顾客满意的原则。",
  "发现更多": "发现更多",
  "阅读详情": "阅读详情",
  "江苏中奕和创智能科技有限公司始于2018年，注册资金5000万元，是以研制生产静音电站、取力发电、特种电机、舰船电站、新能源发电等系列产品，产品的主要特点是“人无我有，人有我优”。": "江苏中奕和创智能科技有限公司始于2018年，注册资金5000万元，是以研制生产静音电站、取力发电、特种电机、舰船电站、新能源发电等系列产品，产品的主要特点是“人无我有，人有我优”。",
  "电源类主要有航空交直流电源车、航空蓄电池充电车、航空蓄电池启动车、自行式应急发电车等产品，可满足飞机起动和通电检查，蓄电池充电及应急供电的保障需求。": "电源类主要有航空交直流电源车、航空蓄电池充电车、航空蓄电池启动车、自行式应急发电车等产品，可满足飞机起动和通电检查，蓄电池充电及应急供电的保障需求。",
  "通信指挥车是一种集成了多种通信手段和指挥、调度、会商、办公、保障等功能的特种车辆。": "通信指挥车是一种集成了多种通信手段和指挥、调度、会商、办公、保障等功能的特种车辆。",
  "应急通信车主要是提供快速、及时的应急救助服务，为社会公共安全提供强有力的保障。": "应急通信车主要是提供快速、及时的应急救助服务，为社会公共安全提供强有力的保障。",
  "消防车主要是用于救火或其它紧急抢救用途的车辆。": "消防车主要是用于救火或其它紧急抢救用途的车辆。",
  "救护车主要是用于在紧急情况下，如事故、疾病等，快速将伤病者送往医院进行救治。": "救护车主要是用于在紧急情况下，如事故、疾病等，快速将伤病者送往医院进行救治。",
  "多功能拖车主要是用途提供动力电源，照明和调节环境温度。": "多功能拖车主要是用途提供动力电源，照明和调节环境温度。",
  "房车是一种具有居家必备的基本设施的车辆。": "房车是一种具有居家必备的基本设施的车辆。",
  "主要由柴油水冷发动机、水冷励磁发电机、分体式散热器、静音箱罩、远控面板、水冷阻抗复合消音器、水冷排气管、外置蓄电池及其他辅助设备组成。": "主要由柴油水冷发动机、水冷励磁发电机、分体式散热器、静音箱罩、远控面板、水冷阻抗复合消音器、水冷排气管、外置蓄电池及其他辅助设备组成。",
  "全水冷单循环技术，具有操作简单、尺寸小、重量轻、噪音低的优点。": "全水冷单循环技术，具有操作简单、尺寸小、重量轻、噪音低的优点。",
  "主要由柴油水冷发动机、水冷盘式发电机、逆变器、分体式散热器、静音箱罩、远控面板、阻抗复合水冷消音器、水冷排气歧管、外置蓄电池及其他辅助设备组成。": "主要由柴油水冷发动机、水冷盘式发电机、逆变器、分体式散热器、静音箱罩、远控面板、阻抗复合水冷消音器、水冷排气歧管、外置蓄电池及其他辅助设备组成。",
  "水冷+风冷散热系统，环境适应性强，尺寸小、重量轻、功率密度高。": "水冷+风冷散热系统，环境适应性强，尺寸小、重量轻、功率密度高。",
  "中型基站车通过装载的专用传输设备，可以提供语音、短信、数据及视频等信息互通。": "中型基站车通过装载的专用传输设备，可以提供语音、短信、数据及视频等信息互通。",
  "标配内置蓄电池和油箱，易操作、静音、防雨，可定制化生产、价格实惠。": "标配内置蓄电池和油箱，易操作、静音、防雨，可定制化生产、价格实惠。",
  "主要由柴油水冷发动机、风冷励磁发电机、散热器、静音箱罩、远控面板、阻抗复合消音器、低温蓄电池及其他辅助设备组成。": "主要由柴油水冷发动机、风冷励磁发电机、散热器、静音箱罩、远控面板、阻抗复合消音器、低温蓄电池及其他辅助设备组成。",
  "可定制化生产、通用型、紧凑型、隐蔽型。一体化结构设计，体积小、重量轻，布局紧凑。": "可定制化生产、通用型、紧凑型、隐蔽型。一体化结构设计，体积小、重量轻，布局紧凑。",
  "低噪音，低排放，低油耗，全球联保，维修方便；": "低噪音，低排放，低油耗，全球联保，维修方便；",
  "功率(1500RPM/50HZ):70KW-400KW(88KVA-500KVA)；": "功率(1500RPM/50HZ):70KW-400KW(88KVA-500KVA)；",
  "垣福电机，利莱森玛、马拉松、西门子、康富、孚日可选；": "垣福电机，利莱森玛、马拉松、西门子、康富、孚日可选；",
  "主要适用于电信、体育赛事、小型至大型工厂、灯塔、混合能源。": "主要适用于电信、体育赛事、小型至大型工厂、灯塔、混合能源。",
  "主要适用于矿山、电站、农场、石油天然气、租赁电源、高空应用、极热地区应用、体育赛事、大中型工厂。": "主要适用于矿山、电站、农场、石油天然气、租赁电源、高空应用、极热地区应用、体育赛事、大中型工厂。",
  "主要适用于矿山、电站、农场、石油天然气、港口及近海、公路或越野移动电源(电源卡车)、租赁电源、重型建筑设备、高空应用、极热区域应用、极冷区域应用、泵应用、体育赛事、中小型工厂。": "主要适用于矿山、电站、农场、石油天然气、港口及近海、公路或越野移动电源(电源卡车)、租赁电源、重型建筑设备、高空应用、极热区域应用、极冷区域应用、泵应用、体育赛事、中小型工厂。",
  "光-油-储一体机微电站系列通过集成太阳能、储能电池系统、柴油发电机组和逆变器组成。": "光-油-储一体机微电站系列通过集成太阳能、储能电池系统、柴油发电机组和逆变器组成。",
  "发电机是利用原车冗余空间及发动机原有螺接孔位进行安装；": "发电机是利用原车冗余空间及发动机原有螺接孔位进行安装；",
  "不需要新增开孔，不占用车辆上装空间，对车辆性能无影响；": "不需要新增开孔，不占用车辆上装空间，对车辆性能无影响；",
  "发电机体积小，重量轻，发电效率高，发热低；": "发电机体积小，重量轻，发电效率高，发热低；",
  "巧妙利用车辆空间，不影响整车性能；": "巧妙利用车辆空间，不影响整车性能；",
  "低速满功率输出，供电稳定；": "低速满功率输出，供电稳定；",
  "电机水冷结构设计，防护等级可达IP57；": "电机水冷结构设计，防护等级可达IP57；",
  "采用高频PWM和软开关变换技术，整机效率高；": "采用高频PWM和软开关变换技术，整机效率高；",
  "解决动中通连续刹车情况下供电不稳定的难题；": "解决动中通连续刹车情况下供电不稳定的难题；",
  "选用盘式水冷电机，长度较普通电机较少30-60cm，重量减少50-90kg；": "选用盘式水冷电机，长度较普通电机较少30-60cm，重量减少50-90kg；",
  "单侧检修门，更人性化的设计，便于日常维修保养；": "单侧检修门，更人性化的设计，便于日常维修保养；",
  "全封闭式隔音罩设计，静音效果好；": "全封闭式隔音罩设计，静音效果好；",
  "采用二级减震系统，减震效果好；": "采用二级减震系统，减震效果好；",
  "电气性能指标优异；": "电气性能指标优异；",
  "尺寸小、重量轻、功率密度高；": "尺寸小、重量轻、功率密度高；",
  "水冷励磁电机，稳定性更高；": "水冷励磁电机，稳定性更高；",
  "短轴风冷励磁电机，尺寸更小，重量更轻；": "短轴风冷励磁电机，尺寸更小，重量更轻；",
  "半封闭式隔音罩设计，静音效果好；": "半封闭式隔音罩设计，静音效果好；",
  "低噪声、低振动、低红外特征；": "低噪声、低振动、低红外特征；",
  "智能化控制（本地、远程双启动控制面板）；": "智能化控制（本地、远程双启动控制面板）；",
  "可定制化生产；": "可定制化生产；",
  "电启动、弹簧启动、空气启动可选；": "电启动、弹簧启动、空气启动可选；",
  "柴油机监控仪、发电机组控制屏可选；": "柴油机监控仪、发电机组控制屏可选；",
  "采用高频PWM 和软开关变换技术，整机效率高；": "采用高频PWM 和软开关变换技术，整机效率高；",
  "体积小、重量轻、环境适应能力强；": "体积小、重量轻、环境适应能力强；",
  "一体式设计，免安装，运输便利、操作简单，维护方便，高效美观，可根据需求定制，适用于多种场景。": "一体式设计，免安装，运输便利、操作简单，维护方便，高效美观，可根据需求定制，适用于多种场景。",
  "输出电压可控，低速满功率输出，供电稳定；": "输出电压可控，低速满功率输出，供电稳定；",
  "钛酸锂电池系统提供电量1。9kWh，满足系统自放电3min；": "钛酸锂电池系统提供电量1.9kWh，满足系统自放电3min；",
  "结构紧凑、轻便、体积小、重量轻、环境适应能力强；": "结构紧凑、轻便、体积小、重量轻、环境适应能力强；",
  "额定电压：AC220V/380V；": "额定电压：AC220V/380V；",
  "转速范围：750rpm～2300rpm；": "转速范围：750rpm～2300rpm；",
  "全转速范围内可以额定功率输出；": "全转速范围内可以额定功率输出；",
  "目前，公司研制、生产和售后服务能力和队伍已经建成，相应的生产厂房和设施已经到位，生产设备也已经就位，开始了生产制造和运营，整个队伍已经达到了20余人，完全具备了高端装备和车辆改装生产的资质和能力，也具备了7×24小时售后服务保障能力。": "目前，公司研制、生产和售后服务能力和队伍已经建成，相应的生产厂房和设施已经到位，生产设备也已经就位，开始了生产制造和运营，整个队伍已经达到了20余人，完全具备了高端装备和车辆改装生产的资质和能力，也具备了7×24小时售后服务保障能力。",
  "公司在生产及售后服务方面均具备优秀的能力。": "公司在生产及售后服务方面均具备优秀的能力。",
  "全面获得高端装备及车辆改装生产的资质，并具备了高精尖的生产力。": "全面获得高端装备及车辆改装生产的资质，并具备了高精尖的生产力。",
  "建立了完善的售后服务体系，并具备7×24小时的全方位服务保障能力。": "建立了完善的售后服务体系，并具备7×24小时的全方位服务保障能力。",
  "售后服务保障地址": "售后服务保障地址",
  "中奕和创（香港）发展工业有限公司": "中奕和创（香港）发展工业有限公司",
  "沙特阿拉伯阿尔塔卡索西街7767，奥拉亚区-利雅得12332。": "沙特阿拉伯阿尔塔卡索西街7767，奥拉亚区-利雅得12332。",
  "联系人": "联系人",
  "雷仕清": "雷仕清",
  "国际维保电话": "国际维保电话",
  "国内维保电话": "国内维保电话",
  "中奕和创（香港）发展工业有限公司（愿景动力）于2023年在沙特注册成立的一家本土化高端装备制造企业。公司集研发、制造、销售、服务为一体，公司拥有完整的专业技术队伍和完善的生产制造条件，主营业务以特种车辆改装和高端发电设备为主，立足沙特，辐射中东，服务全球，打造愿景动力，世界品牌。": "中奕和创（香港）发展工业有限公司（愿景动力）于2023年在沙特注册成立的一家本土化高端装备制造企业。公司集研发、制造、销售、服务为一体，公司拥有完整的专业技术队伍和完善的生产制造条件，主营业务以特种车辆改装和高端发电设备为主，立足沙特，辐射中东，服务全球，打造愿景动力，世界品牌。",
  "公司简介": "公司简介",
  "企业核心价值观": "企业核心价值观",
  "让中奕人都能感受企业以人为本的追求。让中奕人动力源源不绝，正如我们的产品⸺永不间断！": "让中奕人都能感受企业以人为本的追求。让中奕人动力源源不绝，正如我们的产品 ⸺ 永不间断！",
  "我们不管在任何情况下，都要保持年轻、热血、以及热泪盈眶！应当永远怀着敬畏之心继续向前，不忘初心，砥砺前行！": "我们不管在任何情况下，都要保持年轻、热血、以及热泪盈眶！应当永远怀着敬畏之心继续向前，不忘初心，砥砺前行！",
  "合作伙伴": "合作伙伴",
  "公司的核心合作伙伴阵容强大，涵盖了如帕金斯、康明斯、MTU、沃尔沃、潍柴、玉柴和上柴等业内知名品牌，我们与这些合作伙伴之间建立了稳固的战略合作关系，共同推动技术创新和产业升级。": "公司的核心合作伙伴阵容强大，涵盖了如帕金斯、康明斯、MTU、沃尔沃、潍柴、玉柴和上柴等业内知名品牌，我们与这些合作伙伴之间建立了稳固的战略合作关系，共同推动技术创新和产业升级。",
  "企业大事记": "企业大事记",
  "资质荣誉": "资质荣誉",
  "持有专利": "持有专利",
  "荣获奖项": "荣获奖项",
  "认证证书": "认证证书",
  "地址：沙特阿拉伯阿尔塔卡索西街7767，奥拉亚区-利雅得12332": "地址：沙特阿拉伯阿尔塔卡索西街7767，奥拉亚区-利雅得12332",
  "财务部部长": "财务部部长",
  "1万-1。5万": "1万-1.5万",
  "沙特": "沙特",
  "5年以上": "5年以上",
  "本科": "本科",
  "岗位描述": "岗位描述",
  "岗位职责:": "岗位职责:",
  "主持财务部全面工作，对财务部工作效果和结果负责;建立健全公司的财务管理制度;组织编制公司预算和执行预算、决算，参与拟订资金筹措和使用方案，确保资金的有效使用;按照会计制度编制财务综合分析报告和专题分析报告，为公司领导决策提供可靠的依据，负责公司税务筹划工作，与税务、审计、银行等进行联络对接;负责公司融资(银行融资、股权融资)工作和规划，负责内控体系建设，确保公司财务运行安全。": "主持财务部全面工作，对财务部工作效果和结果负责;建立健全公司的财务管理制度;组织编制公司预算和执行预算、决算，参与拟订资金筹措和使用方案，确保资金的有效使用;按照会计制度编制财务综合分析报告和专题分析报告，为公司领导决策提供可靠的依据，负责公司税务筹划工作，与税务、审计、银行等进行联络对接;负责公司融资(银行融资、股权融资)工作和规划，负责内控体系建设，确保公司财务运行安全。",
  "专业技能:": "专业技能:",
  "大学本科以上学历，财务管理、会计等相关专业毕业，具有沙特阿拉伯的财务管理、税务筹划知识，丰富的资产管理、预决算管理、投融资财务管理经验，对科技型、牛产型实体公司财务管理经验丰富。": "大学本科以上学历，财务管理、会计等相关专业毕业，具有沙特阿拉伯的财务管理、税务筹划知识，丰富的资产管理、预决算管理、投融资财务管理经验，对科技型、牛产型实体公司财务管理经验丰富。",
  "要求:": "要求:",
  "年龄不超过45岁，男性，专业从事财务工作不少于5年，熟悉英语阿拉伯语，了解中东地区税务政策、会计法规、进出口贸易相关政策，能适应长期出差，有沙特工作经验的优先考虑。": "年龄不超过45岁，男性，专业从事财务工作不少于5年，熟悉英语阿拉伯语，了解中东地区税务政策、会计法规、进出口贸易相关政策，能适应长期出差，有沙特工作经验的优先考虑。",
  "销售经理": "销售经理",
  "1万起": "1万起",
  "3年以上": "3年以上",
  "开拓新客户和维护老客户，做好客户关系运维，收集市场信息并反馈，协助销售部负责人制定部门年度经营计划，负责规定地区、规定领域的销售业务开展，完成公司下达的销售任务。": "开拓新客户和维护老客户，做好客户关系运维，收集市场信息并反馈，协助销售部负责人制定部门年度经营计划，负责规定地区、规定领域的销售业务开展，完成公司下达的销售任务。",
  "具有丰富的柴油发电机组等产品、机电一体化产品销售经验，具有很强的市场开拓精神。可熟练使用各种办公软件，可独立制作标书及投标，熟悉商务接待礼仪。": "具有丰富的柴油发电机组等产品、机电一体化产品销售经验，具有很强的市场开拓精神。可熟练使用各种办公软件，可独立制作标书及投标，熟悉商务接待礼仪。",
  "大学专科及以上学历，市场营销、机电一体化、汽车结构或机械结构相关专业毕业，年龄不超过45岁，男女不限，身体素质优良。熟悉英语或者阿拉伯语者优先。": "大学专科及以上学历，市场营销、机电一体化、汽车结构或机械结构相关专业毕业，年龄不超过45岁，男女不限，身体素质优良。熟悉英语或者阿拉伯语者优先。",
  "人力资源主管": "人力资源主管",
  "1万": "1万",
  "制定公司招聘、培训、薪酬体系、绩效考核等制度，并贯彻实施，根据公司制定的培训计划，配合相关部门组织人员参加培训，评估培训效果，做好新进员工的职前培训及试用期的考核考评工作等。": "制定公司招聘、培训、薪酬体系、绩效考核等制度，并贯彻实施，根据公司制定的培训计划，配合相关部门组织人员参加培训，评估培训效果，做好新进员工的职前培训及试用期的考核考评工作等。",
  "对中东地区人力资源招聘、薪酬、绩效管理非常熟悉，对相关的法律法规非常熟悉;对相关的福利政策非常了解。": "对中东地区人力资源招聘、薪酬、绩效管理非常熟悉，对相关的法律法规非常熟悉;对相关的福利政策非常了解。",
  "大学本科上学历，人力资源或企业管理类相关专业毕业优先;五年以上相关工作经验，有制造行业相关工作背景者优先;在人员招募、引进、培训开发及员工考核、激励等方面有实际操作能力，熟悉国家相关劳动法律、法规，熟悉人力资源管理工作流程和运作方式;良好的计算机水平，能够熟练操作OFFICE办公软件。熟悉英语或者阿拉伯语者优先。": "大学本科上学历，人力资源或企业管理类相关专业毕业优先;五年以上相关工作经验，有制造行业相关工作背景者优先;在人员招募、引进、培训开发及员工考核、激励等方面有实际操作能力，熟悉国家相关劳动法律、法规，熟悉人力资源管理工作流程和运作方式;良好的计算机水平，能够熟练操作OFFICE办公软件。熟悉英语或者阿拉伯语者优先。",
  "联络我们，我们将尽快回答您的问题": "联络我们，我们将尽快回答您的问题",
  "带星号（*）的字段为必填字段": "带星号（*）的字段为必填字段",
  "名": "名",
  "姓": "姓",
  "联系电话": "联系电话",
  "电子邮箱": "电子邮箱",
  "公司名称": "公司名称",
  "您需要那些帮助？": "您需要那些帮助？",
  "提交信息": "提交信息",
  "我们的产品服务": "我们的产品服务",
  "坚持质量为本，服务至上的原则": "坚持质量为本，服务至上的原则",

  "尺寸小、重量轻、噪音低等优点": "尺寸小、重量轻、噪音低等优点",
  "主要由柴油水冷发动机、发电机、分体式散热器、静音箱罩、远控面板、水冷阻抗复合消音器、水冷排气管、蓄电池及其他辅助设备组成。": "主要由柴油水冷发动机、发电机、分体式散热器、静音箱罩、远控面板、水冷阻抗复合消音器、水冷排气管、蓄电池及其他辅助设备组成。",
  "水冷+风冷散热系统，环境适应性强": "水冷+风冷散热系统，环境适应性强",
  "尺寸小、重量轻、功率密度高": "尺寸小、重量轻、功率密度高",
  "主要由发动机、发电机、散热器、静音箱罩、控制面板，蓄电池及其他辅助设备组成。": "主要由发动机、发电机、散热器、静音箱罩、控制面板，蓄电池及其他辅助设备组成。",
  "标配内置蓄电池和油箱": "标配内置蓄电池和油箱",
  "易操作、静音、防雨": "易操作、静音、防雨",
  "可定制化生产、价格实惠。": "可定制化生产、价格实惠。",
  "可定制化生产、通用型、紧凑型、隐蔽型。": "可定制化生产、通用型、紧凑型、隐蔽型。",
  "一体化结构设计": "一体化结构设计",
  "体积小": "体积小",
  "重量轻": "重量轻",
  "布局紧凑": "布局紧凑",
  "主要由发动机、发电机、散热器、机组底座、控制箱、消音器、蓄电池及其他辅助设备组成。": "主要由发动机、发电机、散热器、机组底座、控制箱、消音器、蓄电池及其他辅助设备组成.",
  "低噪音，低排放，低油耗，全球联保，维修方便。": "低噪音，低排放，低油耗，全球联保，维修方便.",
  "功率(1500RPM/50HZ):70KW-400KW(88KVA-500KVA)。": "功率(1500RPM/50HZ):70KW-400KW(88KVA-500KVA).",
  "斯垣福电机，利莱森玛、马拉松、西门子、康富、孚日可选。": "斯垣福电机，利莱森玛、马拉松、西门子、康富、孚日可选。",
  "电启动、弹簧启动、空气启动可选": "电启动、弹簧启动、空气启动可选",
  "柴油机监控仪、发电机组控制屏可选。": "柴油机监控仪、发电机组控制屏可选。",
  "主要是由发电机、控制单元、蓄电池和安装支架等组成。": "主要由有发电机、控制单元、蓄电池和安装支架等组成。",
  "主要是由发电机、中压配电箱、供配电操控终端、传动轴和安装支架等组成": "主要是由发电机、中压配电箱、供配电操控终端、传动轴和安装支架等组成",
  "主要是由取力发电机、电源转换器、电源管理器、储能电源、散热系统和显控盒等组成。": "主要是由取力发电机、电源转换器、电源管理器、储能电源、散热系统和显控盒等组成。",

  "暂无参数": "暂无参数",
  "中奕和创（香港）发展工业有限公司（愿景动力）于2023年在沙特注册成立的一家拥有军用和民用许可的本土化高端装备制造企业。": "中奕和创（香港）发展工业有限公司（愿景动力）于2023年在沙特注册成立的一家拥有军用和民用许可的本土化高端装备制造企业。",
  "全水冷单循环技术": "全水冷单循环技术",
  "具有操作简单": "具有操作简单",
  "尺寸小、重量轻、超静音等优点": "尺寸小、重量轻、超静音等优点",
  "提交成功": "提交成功",
  "中奕和创(香港)发展工业有限公司": "中奕和创 (香港)发展工业有限公司",

  "复制成功": "复制成功",
  "提交失败": "提交失败",
  "不能为空": "不能为空",

  "立足沙特-辐射中东-服务全球": "立足沙特 辐射中东 服务全球",
  "特种电站": "特种电站",

  "查看产品手册": "查看产品手册",
  "上一页": "上一页",
  "下一页": "下一页",
  "点击下载": "点击下载",
  "关闭": "关闭",
  "申请下载产品手册pdf文件": "申请下载产品手册pdf文件",

  "+966547092658": "+966 547092658",
  "+8617377355871": "+86 17377355871",

  "冷藏车": "冷藏车",
  "主要应用卫生医疗、勤务保障、应急救援，冷链物流和房车露营等领域。": "主要应用卫生医疗、勤务保障、应急救援，冷链物流和房车露营等领域。",

}
