<template>
  <div class="header-main">
    <div class="header-logo-box">
      <img @click="goHome()" class="header-logo" src="../../assets/logo2.png"/>
      <p @click="goHome()" class="header-company">{{$t('h.中奕和创(香港)发展工业有限公司')}}</p>
    </div>
    <div class="header-box">
      <div class="header-left">
        <!--<img class="logo-png" @click="goHome" src="../../assets/logo.png"/>-->
      </div>
      <div class="header-right">
        <el-menu :default-active="activeIndex"
                 class="el-menu-demo"
                 mode="horizontal"
                 background-color="#009B40"
                 text-color="#fff"
                 active-text-color="#009B40"
                 @select="handleSelect">
          <el-menu-item index="/">{{$t('h.首页')}}</el-menu-item>
          <el-menu-item index="productCenter">{{$t('h.产品中心')}}</el-menu-item>
          <el-menu-item index="serviceSupport">{{$t('h.服务支持')}}</el-menu-item>
          <el-menu-item index="newsList">{{$t('h.新闻资讯')}}</el-menu-item>
          <el-menu-item index="aboutUs">{{$t('h.关于我们')}}</el-menu-item>
          <el-menu-item index="contactUs">{{$t('h.联络我们')}}</el-menu-item>
        </el-menu>
        <el-dropdown @command="chooseLang">
        <span class="el-dropdown-link">
          {{$t('h.语言')}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">中文</el-dropdown-item>
            <el-dropdown-item command="2">English</el-dropdown-item>
            <el-dropdown-item command="3">العربية</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Header",
    data() {
      return{
        activeIndex: '',
      }
    },
    watch: {
      $route: {
        handler: function(val, oldVal){
          console.log(val);
          if (val.name === 'home') {
            this.activeIndex = "/"
          }
          if (val.name === 'productCenter' || val.name === 'tezhongList' || val.name === 'tezhongDetail' || val.name === 'xiandianList' || val.name === 'xiandianClass' || val.name === 'xiandianDetail') {
            this.activeIndex = "productCenter"
          }
          if (val.name === 'serviceSupport') {
            this.activeIndex = "serviceSupport"
          }
          if (val.name === 'newsList' || val.name === 'newsDetail1'|| val.name === 'newsDetail2'|| val.name === 'newsDetail3') {
            this.activeIndex = "newsList"
          }
          if (val.name === 'aboutUs') {
            this.activeIndex = "aboutUs"
          }
          if (val.name === 'contactUs') {
            this.activeIndex = "contactUs"
          }
        },
        // 深度观察监听
        deep: true
      }
    },
    created() {
      if (this.$route.name === 'home') {
        this.activeIndex = "/"
      }
      if (this.$route.name === 'productCenter' || this.$route.name === 'tezhongList' || this.$route.name === 'tezhongDetail' || this.$route.name === 'xiandianList' || this.$route.name === 'xiandianClass' || this.$route.name === 'xiandianDetail') {
        this.activeIndex = "productCenter"
      }
      if (this.$route.name === 'serviceSupport') {
        this.activeIndex = "serviceSupport"
      }
      if (this.$route.name === 'newsList' || this.$route.name === 'newsDetail1' || this.$route.name === 'newsDetail2' || this.$route.name === 'newsDetail3') {
        this.activeIndex = "newsList"
      }
      if (this.$route.name === 'aboutUs') {
        this.activeIndex = "aboutUs"
      }
      if (this.$route.name === 'contactUs') {
        this.activeIndex = "contactUs"
      }
    },
    methods: {
      /**
       * 回到首页
       */
      goHome() {
        this.$router.push("/")
      },
      /**
       * 选择语言
       */
      chooseLang(e) {
        // 选择中文
        if (e === '1') {
          localStorage.setItem("locale", "zh")
        }
        // 选择英语
        if (e === '2') {
          localStorage.setItem("locale", "en")
        }
        // 选择阿拉伯语
        if (e === '3') {
          localStorage.setItem("locale", "ar")
        }
        window.location.reload();
      },
      /**
       * 菜单的选择回调
       */
      handleSelect(e) {
        this.$router.push(e)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .header-main {
    width: 100%;
    height: 100%;
  }

  .header-logo-box {
    width: 100%;
    height: 100px;
    padding: 0 120px;
    display: flex;
    align-items: center;
    background-color: #ffffff;

    .header-logo {
      height: 80px;
      cursor: pointer;
    }
    .header-company {
      padding-left: 40px;
      font-size: 26px;
      cursor: pointer;
    }
  }

  .header-box {
    width: 100%;
    height: 60px;
    background-color: #009B40;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;

    .header-left {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .header-right {
      display: flex;
      align-items: center;

      .el-dropdown-link {
        margin-left: 10px;
        font-size: 18px;
        cursor: pointer;
        color: #ffffff;
        line-height: 22px;
      }
    }

  }

  .logo-png {
    height: 50px;
    margin-left: 90px;
  }

  .logo-text {
    color: #ffffff;
    font-size: 22px;
    margin-left: 16px;
  }

</style>
