<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

  import Layout from "./layout/Layout"

  export default {
    components: { Layout },
    data() {
      return{
        type: 'pc',
        arCountryList: ['阿尔及利亚','巴林','科摩罗','吉布提','埃及','伊拉克','约旦','科威特','黎巴嫩','利比亚','毛里塔尼亚','摩洛哥','阿曼','巴勒斯坦','卡塔尔','沙特阿拉伯','索马里','苏丹','突尼斯','阿联酋','也门','叙利亚']
      }
    },
    created() {
      console.log(111, this.isMobileViewport)
      this.getLocal()
      if (this.isMobileViewport) {
        // 手机端
        // window.location.href = 'http://visionpowertech.com/h5'
        // window.location.href = 'https://dev.youliao.love/'
      }
      // 当本地没有语言缓存的话，则自动获取当地的语言
      if (!localStorage.getItem("locale")) {
        // this.getLocal()
        // this.$router.go(0)
      }
    },
    computed: {
      isMobileViewport() {
        return window.matchMedia("(max-width: 767px)").matches;
      },
    },
    methods: {
      /**
       * 获取对应的地区
       */
      getLocal() {
        this.$axios.get("https://myip.ipip.net/json").then(res => {
          try {
            let location = res.data.data.location[0]
            if (location === '中国') {
              console.log(222, localStorage.getItem("locale"))
              if (!localStorage.getItem("locale") || localStorage.getItem("locale") == '') {
                localStorage.setItem("locale", 'zh')
              }
              localStorage.setItem("isChina", 1)
            } else if (this.arCountryList.includes(location)) {
              if (!localStorage.getItem("locale") || localStorage.getItem("locale") == '') {
                localStorage.setItem("locale", 'ar')
              }
              localStorage.setItem("isChina", 2)
            } else {
              if (!localStorage.getItem("locale") || localStorage.getItem("locale") == '') {
                localStorage.setItem("locale", 'en')
              }
              localStorage.setItem("isChina", 2)
            }
          } catch (e) {
            console.log(e)
            localStorage.setItem("locale", 'en')
            localStorage.setItem("isChina", 2)
          }
        }).catch(err => {
          console.log(err)
          localStorage.setItem("locale", 'en')
          localStorage.setItem("isChina", 2)
        })
      },
    }
  }
</script>
<style>

</style>
