<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <el-button>12121</el-button>

    <p>{{$t('h.欢迎')}}</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  }
}
</script>
<style lang="scss" scoped>
  .home {
    background-color: red;
    p {
      color: blue;
    }
  }
</style>
