import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n/index' //国际化
import "@/assets/css/main.css"
// import 'amfe-flexible'
import ElementUI from 'element-ui';
import "./assets/css/element-variables.scss"

import '@/assets/font/font.css'
// import './element-variables.scss'
// import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
Vue.prototype.$axios = axios

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
