<template>
  <div class="layout-box">
    <el-container>
      <el-header class="header-box" height="160px">
        <Header/>
      </el-header>
      <el-main class="el-main-box">
        <router-view/>
      </el-main>
      <el-footer class="footer-box" height="460px">
        <Footer/>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

  import Header from "../components/Header/Header"
  import Footer from "../components/Footer/Footer"

  export default {
    name: "Layout",
    components: {
      Header,
      Footer
    }
  }
</script>

<style lang="scss" scoped>
  .layout-box {
    width: 100%;

    .header-box {
      width: 100%;
      height: 160px !important;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
    }
    .footer-box {
      height: 460px !important;
    }
    .el-main-box {
      margin-top: 160px !important;
    }
  }
</style>
