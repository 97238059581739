import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "axios"
import { i18n } from '../i18n/index' //国际化
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import("../layout/Layout"),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('../views/home/home.vue')
      },
      {
        path: '/productCenter',
        name: 'productCenter',
        component: () => import('../views/productCenter/productCenter.vue')
      },
      {
        path: '/tezhongList',
        name: 'tezhongList',
        component: () => import('../views/tezhong/tezhongList.vue')
      },
      {
        path: '/tezhongDetail',
        name: 'tezhongDetail',
        component: () => import('../views/tezhong/tezhongDetail.vue')
      },
      {
        path: '/xiandianList',
        name: 'xiandianList',
        component: () => import('../views/xiandian/xiandianList.vue')
      },
      {
        path: '/xiandianClass',
        name: 'xiandianClass',
        component: () => import('../views/xiandian/xiandianClass.vue')
      },
      {
        path: '/xiandianDetail',
        name: 'xiandianDetail',
        component: () => import('../views/xiandian/xiandianDetail.vue')
      },
      {
        path: '/serviceSupport',
        name: 'serviceSupport',
        component: () => import('../views/serviceSupport/serviceSupport.vue')
      },
      {
        path: '/newsList',
        name: 'newsList',
        component: () => import('../views/news/newsList.vue')
      },
      {
        path: '/newsDetail1',
        name: 'newsDetail1',
        component: () => import('../views/news/newsDetail1.vue')
      },
      {
        path: '/newsDetail2',
        name: 'newsDetail2',
        component: () => import('../views/news/newsDetail2.vue')
      },
      {
        path: '/newsDetail3',
        name: 'newsDetail3',
        component: () => import('../views/news/newsDetail3.vue')
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('../views/aboutUs/aboutUs.vue')
      },
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('../views/contactUs/contactUs.vue')
      },
    ]
  }
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import('../views/home/home.vue')
  // },
  // {
  //   path: '/productCenter',
  //   name: 'productCenter',
  //   component: () => import('../views/productCenter/productCenter.vue')
  // },
  // {
  //   path: '/tezhongList',
  //   name: 'tezhongList',
  //   component: () => import('../views/tezhong/tezhongList.vue')
  // },
  // {
  //   path: '/tezhongDetail',
  //   name: 'tezhongDetail',
  //   component: () => import('../views/tezhong/tezhongDetail.vue')
  // },
  // {
  //   path: '/xiandianList',
  //   name: 'xiandianList',
  //   component: () => import('../views/xiandian/xiandianList.vue')
  // },
  // {
  //   path: '/xiandianClass',
  //   name: 'xiandianClass',
  //   component: () => import('../views/xiandian/xiandianClass.vue')
  // },
  // {
  //   path: '/xiandianDetail',
  //   name: 'xiandianDetail',
  //   component: () => import('../views/xiandian/xiandianDetail.vue')
  // },
  // {
  //   path: '/serviceSupport',
  //   name: 'serviceSupport',
  //   component: () => import('../views/serviceSupport/serviceSupport.vue')
  // },
  // {
  //   path: '/newsList',
  //   name: 'newsList',
  //   component: () => import('../views/news/newsList.vue')
  // },
  // {
  //   path: '/newsDetail1',
  //   name: 'newsDetail1',
  //   component: () => import('../views/news/newsDetail1.vue')
  // },
  // {
  //   path: '/aboutUs',
  //   name: 'aboutUs',
  //   component: () => import('../views/aboutUs/aboutUs.vue')
  // },
  // {
  //   path: '/contactUs',
  //   name: 'contactUs',
  //   component: () => import('../views/contactUs/contactUs.vue')
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}


// 全局前置守卫:每次路由切换时，都会调用beforeEach
// to：要跳转到哪 from：当前导航要离开的路由信息 next是否继续跳转路由 如果要就调用next()
router.beforeEach((to, from, next) => {
  // 本地缓存有语言
  if (localStorage.getItem("locale")) {
    // 调用继续路由跳转
    // 屏蔽中国站点
    if (localStorage.getItem("isChina") == 1) {
      // next(false)
      next()
    } else {
      next()
    }
  } else {
    axios.get("https://myip.ipip.net/json").then(res => {
      try {
        let location = res.data.data.location[0]
        if (location === '中国') {
          localStorage.setItem("locale", 'zh')
          i18n.locale = 'zh'
          localStorage.setItem("isChina", 1)
          // next(false)
          next()
        } else if (this.arCountryList.includes(location)) {
          localStorage.setItem("locale", 'ar')
          i18n.locale = 'ar'
          localStorage.setItem("isChina", 2)
          next()
        } else {
          localStorage.setItem("locale", 'en')
          i18n.locale = 'en'
          localStorage.setItem("isChina", 2)
          next()
        }
      } catch (e) {
        console.log(e)
        localStorage.setItem("locale", 'en')
        i18n.locale = 'en'
        localStorage.setItem("isChina", 2)
        next()
      }
    }).catch(err => {
      console.log(err)
      localStorage.setItem("locale", 'en')
      i18n.locale = 'en'
      localStorage.setItem("isChina", 2)
      next()
    })
  }
})

router.afterEach((to, from, next) => {

  document.documentElement.scrollTop = 0
  // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
  document.body.scrollTop = 0

  // let top = document.documentElement.scrollTop || document.body.scrollTop
  // // 实现滚动效果
  // const timeTop = setInterval(() => {
  //   document.body.scrollTop = document.documentElement.scrollTop = top -= 20
  //   if (top <= 0) {
  //     clearInterval(timeTop)
  //   }
  // }, 3)
})

export default router
