export const h = {
  "语言": "English",
  "首页": "Home",
  "产品中心": "Product Centre",
  "服务支持": "Customer Service",
  "新闻资讯": "News",
  "关于我们": "About us",
  "联络我们": "Get in touch",
  "联系我们": "Contact us",
  "隐私政策": "Privacy policy",
  "使用条款": "Terms of use",
  "辅助功能": "accessibility",
  "查看产品": "View product",
  "全球业务": "global business",
  "阅读更多": "Read more",
  "招聘": "Recruitment",
  "招聘岗位": "Job title",
  "2024年6月26日": "June 26, 2024",
  "人才招聘": "Recruitment",
  "了解我们的产品": "Learn about our products",
  "了解我们的企业": "Learn about our company",
  "了解更多": "Learn more",
  "产品服务": "Product service",
  "售后服务地址": "after-sales service address",
  "售后服务热线": "after-sales service hot-line",
  "售后服务": "after-sales service",
  "应急电源车": "Emergency power vehicle",
  "通信指挥车": "Communication command vehicle",
  "应急通信车": "Emergency communication vehicle",
  "消防车": "Fire truck",
  "救护车": "Ambulance",
  "拖车": "Trailer",
  "房车": "RV",
  "中型基站车": "Medium base station Truck",
  "现代奴隶制声明": "Modern Slavery Statement",
  "提交联系方式": "Submit contact information",
  "特种车辆改装": "Special vehicle modification",
  "先进电能源系统": "Advanced power generating system",
  "超静音电站": "Generators for vehicles",
  "超静音电站分类": "Generators for vehicles",
  "UCSS系列": "UCSS series",
  "CSS系列": "CSS series",
  "静音电站": "Silent power station",
  "静音电站分类": "Silent power station classification",
  "通用电站": "General power station",
  "通用电站分类": "General power station classification",
  "军用电站": "Military power station",
  "军用电站分类": "Military power station classification",
  "船用电站": "Marine power station",
  "船用电站分类": "Marin power station classification",
  "发动机系列电站": "Engine series power station",
  "发动机系列电站分类": "Engine series power station classification",
  "帕金斯动力": "Perkins power",
  "博杜安动力": "Baodouin power",
  "康明斯动力": "Cummins power",
  "沃尔沃动力": "Volvo power",
  "上柴动力": "Shangchai power",
  "玉柴动力": "Yuchai power",
  "多源电站": "Multi-source power station",
  "多源电站分类": "Multi-source power station classification",
  "取力发电系统": "Power generation system",
  "KLK特点": "KLK features",
  "驻车取力发电系统": "Parking power generation system",
  "行车取力发电系统": "Driving power generation system",
  "查看参数": "View parameters",
  "产品特点": "Product features",
  "产品参数": "Product parameter",
  "服务中心": "Service centre",
  "了解我们": "About us",
  "暂停": "Pause",
  "播放": "Play",
  "型号": "Model number",
  "功率": "Power",
  "电压": "Voltage",
  "噪音": "Sound",
  "主机参数": "Host parameter",
  "散热器参数": "Radiator parameter",
  "外形尺寸": "Dimensions",
  "重量": "Weight",
  "主用功率": "Main power",
  "备用功率": "Standby power",
  "发动机型号": "Engine model number",
  "开架型": "Open-type",
  "静音型": "Sound-proof type",
  "电子调速": "electronic speed regulation",
  "机械调速": "mechanical speed regulation",
  "调速": "speed regulation",
  "国三排放": "National third emission",
  "高压共轨": "High voltage common rail",
  "控制单元外形尺寸": "Control unit dimensions",
  "控制单元重量": "Control unit weight",
  "发电机重量": "Generator weight",
  "转速范围": "RPM",
  "公司用因业务发展现，寻找与纵不同的你。给自己一个机会，创造属于自己的舞台。": "Due to the development of the business, the company is looking for a unique person.Give yourself a chance to create your own stage.",
  "中奕和创（香港）发展工业有限公司（愿景动力）是中奕和创（香港）": "Hong Kong S.Unity Development Industry Co., Ltd (Vision Power) is a subsidiary of Zhongyi Hechuang (Hong Kong)",
  "发展有限公司于2023年在沙特注册成立的一家拥有军用和民用许可的": "Development Co., Ltd. was registered in Saudi Arabia in 2023 as a company with military and civilian licenses.",
  "本土化高端装备制造企业。": "Localized high-end equipment manufacturing enterprise.",
  "探索更多": "Explore more",
  "特种车辆": "Special Vehicles",
  "特种车辆主要包括：应急电源车、冷藏车、通信指挥车、消防车、救护车和拖车等，提供更快捷、更高效的服务。": "Special vehicles mainly include: emergency power supply vehicles, Refrigerator car，communication command vehicles, fire trucks, ambulances and trailers, etc., providing faster and more efficient services.",
  "发电设备": "Power Generation Equipment",
  "发电设备主要包括：超静音电站、静音电站、特种电站、船用电站，发动机系统电站和多源电站。": "Power generation equipment mainly includes: Generators for vehicles, silent power stations, special power stations, marine power stations, engine system power stations and multi-source power stations.",
  "我们的全球业务": "Our Global Business",
  "开创特种车辆改装和发电设备生产行业的革新业务": "Innovation in the production of special vehicle modifications and power plants.",
  "我们的售后服务": "Our Customer Service",
  "坚持质量为本，服务至上，顾客满意的原则。": "Adhere to the principle of quality-based, service-oriented, and customer satisfaction.",
  "发现更多": "Discover more",
  "阅读详情": "Read more",
  "江苏中奕和创智能科技有限公司始于2018年，注册资金5000万元，是以研制生产静音电站、取力发电、特种电机、舰船电站、新能源发电等系列产品，产品的主要特点是“人无我有，人有我优”。": "Jiangsu Zhongyi Hechuang Intelligent Technology Co., Ltd. was founded in 2018 with a registered capital of 50 million yuan. It develops and produces a series of products such as silent power stations, power generation, special motors, ship power stations, new energy power generation, etc. The main characteristics of the products are 'I have what others don't have, and have superior ones for when they do have'.",
  "电源类主要有航空交直流电源车、航空蓄电池充电车、航空蓄电池启动车、自行式应急发电车等产品，可满足飞机起动和通电检查，蓄电池充电及应急供电的保障需求。": "The power supply category mainly includes aviation AC and DC power supply vehicles, aviation battery charging vehicles, aviation battery starter vehicles, self-propelled emergency power generation vehicles, and other products, that can meet the needs of aircraft starting and power-on inspection, battery charging, and emergency power supply.",
  "通信指挥车是一种集成了多种通信手段和指挥、调度、会商、办公、保障等功能的特种车辆。": "A communication command vehicle is a special vehicle that integrates various communication means and functions such as command, dispatch, consultation, office, and assurance.",
  "应急通信车主要是提供快速、及时的应急救助服务，为社会公共安全提供强有力的保障。": "Emergency communication vehicles provide rapid and timely emergency assistance services to ensure strong social and public safety guarantees.",
  "消防车主要是用于救火或其它紧急抢救用途的车辆。": "Fire engines are primarily vehicles used for fire fighting or other emergency rescue purposes.",
  "救护车主要是用于在紧急情况下，如事故、疾病等，快速将伤病者送往医院进行救治。": "Ambulances are mainly used in emergencies, such as accidents, diseases, etc., to quickly take the injured to hospital for treatment.",
  "多功能拖车主要是用途提供动力电源，照明和调节环境温度。": "Multifunctional trailers are primarily used to provide power, and lighting and regulate ambient temperature.",
  "房车是一种具有居家必备的基本设施的车辆。": "An RV is a vehicle that has the basic facilities necessary for a home.",
  "主要由柴油水冷发动机、水冷励磁发电机、分体式散热器、静音箱罩、远控面板、水冷阻抗复合消音器、水冷排气管、外置蓄电池及其他辅助设备组成。": "It mainly consists of a diesel water-cooled engine, a water-cooled exciter generator, a split radiator, a silent box cover, a remote control panel, a water-cooled impedance composite muffler, a water-cooled exhaust pipe, an external battery, and other auxiliary equipment.",
  "全水冷单循环技术，具有操作简单、尺寸小、重量轻、噪音低的优点。": "Full water-cooled single-cycle technology has the advantages of simple operation, small size, lightweight, and low noise.",
  "主要由柴油水冷发动机、水冷盘式发电机、逆变器、分体式散热器、静音箱罩、远控面板、阻抗复合水冷消音器、水冷排气歧管、外置蓄电池及其他辅助设备组成。": "It mainly consists of a diesel water-cooled engine, a water-cooled disc generator, an inverter, a split radiator, a silent box cover, a remote control panel, an impedance composite water-cooled muffler, a water-cooled exhaust manifold, an external battery, and other auxiliary equipment. Full water cooling single cycle technology, with simple operation, small size, lightweight, super silent, and other advantages.",
  "水冷+风冷散热系统，环境适应性强，尺寸小、重量轻、功率密度高。": "Water-cooled + air-cooled cooling system, strong environmental adaptability, small size, lightweight, and high power density.",
  "中型基站车通过装载的专用传输设备，可以提供语音、短信、数据及视频等信息互通。": "Medium-sized base station vehicles can provide interoperability of voice, SMS, data, and video through special transmission equipment.",
  "标配内置蓄电池和油箱，易操作、静音、防雨，可定制化生产、价格实惠。": "It comes standard with a built-in battery and fuel tank, which is easy to operate, silent, and rainproof. It can be customized and produced at an affordable price.",
  "主要由柴油水冷发动机、风冷励磁发电机、散热器、静音箱罩、远控面板、阻抗复合消音器、低温蓄电池及其他辅助设备组成。": "It consists of a diesel water-cooled engine, air-cooled excitation generator, radiator, silent box cover, remote control panel, impedance composite silencer, cryogenic battery, and other auxiliary equipment.",
  "可定制化生产、通用型、紧凑型、隐蔽型。一体化结构设计，体积小、重量轻，布局紧凑。": "Customizable production, universal, compact, concealed. Integrated structure design, small size, lightweight, and compact layout.",
  "低噪音，低排放，低油耗，全球联保，维修方便；": "Low noise, low emissions, low fuel consumption, global guarantee, easy maintenance；",
  "功率(1500RPM/50HZ):70KW-400KW(88KVA-500KVA)；": "Power(1500RPM/50HZ):70KW-400KW(88KVA-500KVA)；",
  "垣福电机，利莱森玛、马拉松、西门子、康富、孚日可选；": "Yuanfu motors, Leroy-Somer, Marathon, Siemens, Kungfu, and Vosges are optional; Electric start, spring start, and air start are optional; Diesel engine monitor and generator set control panel are optional. ；",

  "主要适用于电信、体育赛事、小型至大型工厂、灯塔、混合能源。": "Mainly suitable for telecommunications, sports events, small to large factories, lighthouses, and mixed energy.",
  "主要适用于矿山、电站、农场、石油天然气、租赁电源、高空应用、极热地区应用、体育赛事、大中型工厂。": "Mainly suitable for mining, power stations, farms, oil and gas, rental power supply, high-altitude applications, applications in extremely hot areas, sports events, and large and medium-sized factories.",
  "主要适用于矿山、电站、农场、石油天然气、港口及近海、公路或越野移动电源(电源卡车)、租赁电源、重型建筑设备、高空应用、极热区域应用、极冷区域应用、泵应用、体育赛事、中小型工厂。": "Mainly suitable for mining, power stations, farms, oil and gas, ports and offshore, on-road or off-road mobile power supplies (power trucks), rental power supplies, heavy construction equipment, high-altitude applications, extreme hot area applications, extreme cold area applications, pump applications, sports events, small and medium-sized factories.",
  "光-油-储一体机微电站系列通过集成太阳能、储能电池系统、柴油发电机组和逆变器组成。": "The light-oil-storage integrated micro power station series is composed of integrated solar energy, energy storage battery system, diesel generator set and inverter.",
  "发电机是利用原车冗余空间及发动机原有螺接孔位进行安装；": "The generator is installed using the redundant space of the original vehicle and the original screw holes of the engine;",
  "不需要新增开孔，不占用车辆上装空间，对车辆性能无影响；": "No need for new holes, do not occupy the vehicle loading space,  and has no impact on vehicle performance;",
  "发电机体积小，重量轻，发电效率高，发热低；": "The generator is small in size, lightweight, has high power generation efficiency and low heat generation;",
  "巧妙利用车辆空间，不影响整车性能；": "Makes clever use of vehicle space without affecting the performance of the vehicle;",
  "低速满功率输出，供电稳定；": "Low-speed full power output, and stable power supply;",
  "电机水冷结构设计，防护等级可达IP57；": "The motor is designed with water-cooling structure and the protection level can reach up to IP57;",
  "采用高频PWM和软开关变换技术，整机效率高；": "Using high-frequency PWM and soft-switching conversion technology, the whole machine has high efficiency;",
  "解决动中通连续刹车情况下供电不稳定的难题；": "Solves the problem of unstable power supply in the case of continuous braking;",
  "选用盘式水冷电机，长度较普通电机较少30-60cm，重量减少50-90kg；": "Using a disc-type water-cooled motor, the length is 30-60cm shorter than ordinary motors, and the weight is reduced by 50-90kg;",
  "单侧检修门，更人性化的设计，便于日常维修保养；": "One-sided access door, more user-friendly design, convenient for daily maintenance;",
  "全封闭式隔音罩设计，静音效果好；": "Fully enclosed soundproof cover design, great mute effect;",
  "采用二级减震系统，减震效果好；": "Adopting a two-level shock absorption system, great shock absorption effect;",
  "电气性能指标优异；": "Excellent electrical performance indicators;",
  "尺寸小、重量轻、功率密度高；": "Small size, lightweight, and high power density;",
  "水冷励磁电机，稳定性更高；": "Water-cooled excitation motor, higher stability;",
  "短轴风冷励磁电机，尺寸更小，重量更轻；": "Short-axis air-cooled excitation motor, smaller in size and lighter in weight;",
  "半封闭式隔音罩设计，静音效果好；": "Semi-enclosed soundproof cover design, and great mute effect;",
  "低噪声、低振动、低红外特征；": "Low sound, low vibration, and low infrared characteristics;",
  "智能化控制（本地、远程双启动控制面板）；": "Intelligent control (local and remote dual-start control panel);",
  "可定制化生产；": "Customizable production;",
  "电启动、弹簧启动、空气启动可选；": "Electric-start, spring-start and air-start are optional;",
  "柴油机监控仪、发电机组控制屏可选；": "Diesel engine monitor and generator set control panel are optional;",
  "采用高频PWM 和软开关变换技术，整机效率高；": "Using high-frequency PWM and soft-switching conversion technology, the overall machine efficiency is high;",
  "体积小、重量轻、环境适应能力强；": "Small size, lightweight and strong environmental adaptability;",
  "一体式设计，免安装，运输便利、操作简单，维护方便，高效美观，可根据需求定制，适用于多种场景。": "Integrated design, installation-free, convenient transportation, simple operation, easy maintenance, efficient and beautiful, can be customized according to needs, and is suitable for a variety of scenarios.",
  "输出电压可控，低速满功率输出，供电稳定；": "The output voltage is controllable, low-speed full power output, and stable power supply;",
  "钛酸锂电池系统提供电量1。9kWh，满足系统自放电3min；": "The lithium titanate battery system provides 1.9kWh of power, which can meet the system self-discharge for 3 minutes;",
  "结构紧凑、轻便、体积小、重量轻、环境适应能力强；": "Compact structure, portable, small size, lightweight, strong environmental adaptability;",
  "额定电压：AC220V/380V；": "Rated Voltage：AC220V/380V；",
  "转速范围：750rpm～2300rpm；": "Speed Range：750rpm～2300rpm；",
  "全转速范围内可以额定功率输出；": "Rated power output can be achieved within the full speed range;",
  "目前，公司研制、生产和售后服务能力和队伍已经建成，相应的生产厂房和设施已经到位，生产设备也已经就位，开始了生产制造和运营，整个队伍已经达到了20余人，完全具备了高端装备和车辆改装生产的资质和能力，也具备了7×24小时售后服务保障能力。": "Currently the company's R&D, production, and after-sales service capabilities and teams have been established, the corresponding production plants and facilities are in place, and the production equipment is in place. Production, manufacturing, and operations have begun. The entire team has reached more than 20 people, fully equipped with high-end It has the qualifications and capabilities to produce equipment and vehicle modifications, and also can provide 24/7 hour after-sale service.",
  "公司在生产及售后服务方面均具备优秀的能力。": "The company has excellent production and after-sales service capabilities.",
  "全面获得高端装备及车辆改装生产的资质，并具备了高精尖的生产力。": "Have obtained comprehensive qualifications for the production of high-end equipment and vehicle modifications, and has high-precision productivity.",
  "建立了完善的售后服务体系，并具备7×24小时的全方位服务保障能力。": "Established a complete after-sales service system and has 24/7 hour all-round service guarantee capabilities。",
  "售后服务保障地址": "after-sales service address",
  "中奕和创（香港）发展工业有限公司": "Hong Kong S.Unity Development Industry Co., Ltd",
  "沙特阿拉伯阿尔塔卡索西街7767，奥拉亚区-利雅得12332。": "7767 Al Taqasso West Street, Olaya District - Riyadh 12332, Saudi Arabia.",
  "联系人": "Contact person",
  "雷仕清": "Lei Shiqing",
  "国际维保电话": "International Telephone number",
  "国内维保电话": "Domestic Telephone number",
  "中奕和创（香港）发展工业有限公司（愿景动力）于2023年在沙特注册成立的一家本土化高端装备制造企业。公司集研发、制造、销售、服务为一体，公司拥有完整的专业技术队伍和完善的生产制造条件，主营业务以特种车辆改装和高端发电设备为主，立足沙特，辐射中东，服务全球，打造愿景动力，世界品牌。": "Hong Kong S.Unity Development Industry Co., Ltd（Vision Power ）was registered in Saudi Arabia in 2023 as a localized high-end equipment manufacturing enterprise . The company integrates R&D, manufacturing, sales, and service. The company has a completely professional technical team and perfect production and manufacturing conditions. Its main business is special vehicle modification and high-end power generation equipment. It is based in Saudi Arabia, radiates to the Middle East, serves the world, and creates Vision power, a world brand.",
  "公司简介": "Company profile",
  "企业核心价值观": "Companies core values",
  "让中奕人都能感受企业以人为本的追求。让中奕人动力源源不绝，正如我们的产品⸺永不间断！": "Let Zhongyi people feel the company's people-oriented pursuit. Let Zhongyi people have endless power, just like our products - never stop!",
  "我们不管在任何情况下，都要保持年轻、热血、以及热泪盈眶！应当永远怀着敬畏之心继续向前，不忘初心，砥砺前行！": "No matter the circumstances, we must remain young and passionate! We should always move forward with awe, never forget our original intention, and forge ahead!",
  "合作伙伴": "Partnerships",
  "公司的核心合作伙伴阵容强大，涵盖了如帕金斯、康明斯、MTU、沃尔沃、潍柴、玉柴和上柴等业内知名品牌，我们与这些合作伙伴之间建立了稳固的战略合作关系，共同推动技术创新和产业升级。": "The company has a strong lineup of core partners, covering well-known brands in the industry such as Perkins, Cummins, MTU, Volvo, Weichai, Yuchai, and Shangchai. We have established a solid strategic partnership with these partners to promote Technological innovation and industrial upgrading jointly.",
  "企业大事记": "Corporate events",
  "资质荣誉": "Honor",
  "持有专利": "Patent",
  "荣获奖项": "Awarded",
  "认证证书": "Certified",
  "地址：沙特阿拉伯阿尔塔卡索西街7767，奥拉亚区-利雅得12332": "Address: 7767 Al Taqasso West Street, Olaya District - Riyadh 12332, Saudi Arabia",
  "财务部部长": "Financial officer",
  "1万-1。5万": "10,000-150,000",
  "沙特": "Saudi",
  "5年以上": "over 5 years",
  "本科": "Undergraduate",
  "岗位描述": "Job description",
  "岗位职责:": "Job responsibilities:",
  "主持财务部全面工作，对财务部工作效果和结果负责;建立健全公司的财务管理制度;组织编制公司预算和执行预算、决算，参与拟订资金筹措和使用方案，确保资金的有效使用;按照会计制度编制财务综合分析报告和专题分析报告，为公司领导决策提供可靠的依据，负责公司税务筹划工作，与税务、审计、银行等进行联络对接;负责公司融资(银行融资、股权融资)工作和规划，负责内控体系建设，确保公司财务运行安全。": "Preside over the overall work of the Finance Department and be responsible for the work effects and results of the Finance Department; establish and improve the company's financial management system; organize the preparation of the company's budget and the implementation of budgets and final accounts, participate in the formulation of fundraising and use plans, and ensure the effective use of funds; following the accounting system Prepare comprehensive financial analysis reports and special analysis reports to provide reliable basis for the company's leadership decision-making. Responsible for the company's tax planning, liaising with taxation, auditing, banks, etc.; responsible for company financing (bank financing, equity financing) work and planning, Responsible for the construction of the internal control system to ensure the safety of the company's financial operations.",
  "专业技能:": "Professional skill:",
  "大学本科以上学历，财务管理、会计等相关专业毕业，具有沙特阿拉伯的财务管理、税务筹划知识，丰富的资产管理、预决算管理、投融资财务管理经验，对科技型、牛产型实体公司财务管理经验丰富。": "University degree or above, financial management, accounting, and other related majors, with Saudi Arabia's financial management, tax planning knowledge, rich experience in asset management, budget management, investment and financing financial management, and rich experience in the financial management of technology-based and cattle-producing entity companies.",
  "要求:": "Requirements:",
  "年龄不超过45岁，男性，专业从事财务工作不少于5年，熟悉英语阿拉伯语，了解中东地区税务政策、会计法规、进出口贸易相关政策，能适应长期出差，有沙特工作经验的优先考虑。": "No more than 45 years old, male, professionally engaged in financial work for no less than 5 years, familiar with English and Arabic, understanding of tax policies, accounting regulations, import and export trade-related policies in the Middle East, able to adapt to long-term business trips, priority will be given to those with work experience in Saudi Arabia .",
  "销售经理": "Sales manager",
  "1万起": "starting from 10,000",
  "3年以上": "over 3 years",
  "开拓新客户和维护老客户，做好客户关系运维，收集市场信息并反馈，协助销售部负责人制定部门年度经营计划，负责规定地区、规定领域的销售业务开展，完成公司下达的销售任务。": "Bring in new customers and maintain old customers, do a good job in customer relationship operation and maintenance, collect market information and give feedback, assist the person in charge of the sales department to formulate the department's annual business plan, be responsible for the development of sales business in specified regions and specified fields, and complete the sales tasks assigned by the company.",
  "具有丰富的柴油发电机组等产品、机电一体化产品销售经验，具有很强的市场开拓精神。可熟练使用各种办公软件，可独立制作标书及投标，熟悉商务接待礼仪。": "Applicants must have rich experience in selling diesel generator sets and other products, as well as mechatronics products, and have a strong market development spirit. Be proficient in using various office software, be able to independently prepare tender documents and bids, and be familiar with business reception etiquette.",
  "大学专科及以上学历，市场营销、机电一体化、汽车结构或机械结构相关专业毕业，年龄不超过45岁，男女不限，身体素质优良。熟悉英语或者阿拉伯语者优先。": "College degree or above, majoring in marketing, mechatronics, automotive structure or mechanical structure, no more than 45 years old, male or female, good physical fitness. Familiarity with English or Arabic is preferred.",
  "人力资源主管": "Human resources manager",
  "1万": "10,000",
  "制定公司招聘、培训、薪酬体系、绩效考核等制度，并贯彻实施，根据公司制定的培训计划，配合相关部门组织人员参加培训，评估培训效果，做好新进员工的职前培训及试用期的考核考评工作等。": "Implement the company's procedures for hiring, training, paying employees, evaluating performance, and other areas. As per the company's training strategy, collaborate with appropriate departments to schedule staff for training, assess the impact of training, and provide new hires pre-employment training and a probationary period. Evaluation and assessment tasks, etc.",
  "对中东地区人力资源招聘、薪酬、绩效管理非常熟悉，对相关的法律法规非常熟悉;对相关的福利政策非常了解。": "Familiar with Middle Eastern human resources hiring, compensation, and performance management; knowledgeable about relevant laws and regulations; knowledgeable with welfare policies.",
  "大学本科上学历，人力资源或企业管理类相关专业毕业优先;五年以上相关工作经验，有制造行业相关工作背景者优先;在人员招募、引进、培训开发及员工考核、激励等方面有实际操作能力，熟悉国家相关劳动法律、法规，熟悉人力资源管理工作流程和运作方式;良好的计算机水平，能够熟练操作OFFICE办公软件。熟悉英语或者阿拉伯语者优先。": "A bachelor's degree in human resources or business management is preferred; more than five years of relevant work experience, and those with a manufacturing industry-related work background are preferred; practical operations in personnel recruitment, introduction, training and development, employee assessment, motivation, etc. Ability, familiar with relevant national labor laws and regulations, familiar with human resources management workflow and operation methods; good computer skills, able to skillfully operate OFFICE software. Applicants familiar with English or Arabic will be given priority. The preferred language is English or Arabic.",
  "联络我们，我们将尽快回答您的问题": "Get in touch with us and we'll respond to your inquiries as soon as possible",
  "带星号（*）的字段为必填字段": "Fields with an asterisk (*) are required",
  "名": "First name",
  "姓": "Last name",
  "联系电话": "Contact number",
  "电子邮箱": "Email",
  "公司名称": "Company name",
  "您需要那些帮助？": "What help do you need?",
  "提交信息": "Submit information",
  "我们的产品服务": "Our products and services",
  "坚持质量为本，服务至上的原则": "Adhere to the principle of quality-based and service-oriented",

  "尺寸小、重量轻、噪音低等优点": "Small size, lightweight, super-silent and other advantages.",
  "主要由柴油水冷发动机、发电机、分体式散热器、静音箱罩、远控面板、水冷阻抗复合消音器、水冷排气管、蓄电池及其他辅助设备组成。": "It mainly consists of a diesel water-cooled engine, a generator, a split radiator, a silent box cover, a remote control panel, a water-cooled impedance composite muffler, a water-cooled exhaust pipe, an battery, and other auxiliary equipment.",
  "水冷+风冷散热系统，环境适应性强": "Water-cooled + air-cooled cooling system, strong environmental adaptability.",
  "尺寸小、重量轻、功率密度高": "small size, lightweight, and high power density.",
  "主要由发动机、发电机、散热器、静音箱罩、控制面板，蓄电池及其他辅助设备组成。": "It is mainly composed of engine, generator, radiator, silent box cover, control panel, battery and other auxiliary equipment.",
  "标配内置蓄电池和油箱": "It comes standard with a built-in battery and fuel tank.",
  "易操作、静音、防雨": "which is easy to operate, silent, and rainproof.",
  "可定制化生产、价格实惠。": "It can be customized and produced at an affordable price.",
  "可定制化生产、通用型、紧凑型、隐蔽型。": "Customizable production, universal, compact， concealed.",
  "一体化结构设计": "Integrated structure design.",
  "体积小": "small size.",
  "重量轻": "lightweight.",
  "布局紧凑": "compact layout.",
  "主要由发动机、发电机、散热器、机组底座、控制箱、消音器、蓄电池及其他辅助设备组成。": "It is mainly composed of engine, generator, radiator, unit base, control box, muffler, battery and other auxiliary equipment.",
  "低噪音，低排放，低油耗，全球联保，维修方便。": "Low noise, low emissions, low fuel consumption, global guarantee, easy maintenance.",
  "功率(1500RPM/50HZ):70KW-400KW(88KVA-500KVA)。": "Power(1500RPM/50HZ):70KW-400KW(88KVA-500KVA).",
  "斯垣福电机，利莱森玛、马拉松、西门子、康富、孚日可选。": "Yuanfu motors, Leroy-Somer, Marathon, Siemens, Kungfu, and Vosges are optional; Electric start, spring start, and air start are optional; Diesel engine monitor and generator set control panel are optional. ",
  "电启动、弹簧启动、空气启动可选": "Electric-start, spring-start, and air-start are optional",
  "柴油机监控仪、发电机组控制屏可选。": "Diesel engine monitor and generator set control panel are optional.",
  "主要是由发电机、控制单元、蓄电池和安装支架等组成。": "It mainly consists of a generator, control unit, battery, and mounting bracket.",
  "主要是由发电机、中压配电箱、供配电操控终端、传动轴和安装支架等组成": "It mainly consists of a generator, medium voltage distribution box, power supply and a distribution control terminal, transmission shaft, and installation bracket.",
  "主要是由取力发电机、电源转换器、电源管理器、储能电源、散热系统和显控盒等组成。": "It mainly consists of a power generator, power converter, power manager, energy storage power supply, cooling system, and display and control box.",

  "暂无参数": "No Parameter",
  "中奕和创（香港）发展工业有限公司（愿景动力）于2023年在沙特注册成立的一家拥有军用和民用许可的本土化高端装备制造企业。": "Hong Kong S.Unity Development Industry Co., Ltd (Vision Power) was registered in Saudi Arabia in 2023 as a localized high-end equipment manufacturing enterprise with military and civilian licenses.",
  "全水冷单循环技术": "Full water-cooling single-cycle technology",
  "具有操作简单": "Easy to operate",
  "尺寸小、重量轻、超静音等优点": "Small size, lightweight, super-silent and other advantages.",
  "提交成功": "Submitted Successfully",
  "中奕和创(香港)发展工业有限公司": "Hong Kong S.Unity Development Industry Co., Ltd",

  "复制成功": "Copy Successful",
  "提交失败": "Submitted Failure",
  "不能为空": "is not empty",

  "立足沙特-辐射中东-服务全球": "Based in Saudi Arabia     Radiant Middle East   Global Services",
  "特种电站": "Special power station",

  "查看产品手册": "View product manual",
  "上一页": "Previous page",
  "下一页": "Next page",
  "点击下载": "Click to download",
  "关闭": "Close",
  "申请下载产品手册pdf文件": "Apply for downloading PDF file of product manual",

  "公司用因业务发展，现寻找与纵不同的你。给自己一个机会，创造属于自己的舞台。": "Due to the development of the business, the company is looking for a unique person.Give yourself a chance to create your own stage.",
  "+966547092658": "+966 547092658",
  "+8617377355871": "+86 17377355871",

  "冷藏车": "Refrigerated truck",
  "主要应用卫生医疗、勤务保障、应急救援，冷链物流和房车露营等领域。": "Mainly used in health care, service support, emergency rescue, cold chain logistics and RV camping and other fields.",

}
