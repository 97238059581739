<template>
  <div class="footer-box" :class="[language === 'ar' ? 'textRight arPadding' : '']">
    <div class="footer-top">
      <div class="footer-left">
        <!--<img class="logo-img" src="../../assets/logo.png"/>-->
        <h1 @click="goContactUs()">{{$t('h.联络我们')}}</h1>
        <p>
          <a v-clipboard:copy="'+9660559317711'" v-clipboard:success="onCopy" style="color: #3AB54A;cursor: pointer;">
            +966 0559317711
          </a>
        </p>
        <a class="email" href="mailto:info@visionpowertech.com">
          <span style="color: #3AB54A;">info@visionpowertech.com</span>
        </a>
        <div class="submit-btn-box">

          <div class="submit-btn" @click="goContactUs">{{$t('h.提交联系方式')}}</div>
        </div>
      </div>
      <div class="footer-right">
        <div class="right-item">
          <h1>{{$t('h.产品中心')}}</h1>
          <p @click="goTeZhongList">{{$t('h.特种车辆')}}</p>
          <p @click="goXiandianList">{{$t('h.发电设备')}}</p>
        </div>
        <div class="right-item">
          <h1>{{$t('h.服务支持')}}</h1>
          <p @click="goServiceSupport()">{{$t('h.全球业务')}}</p>
          <p @click="goServiceSupport(400)">{{$t('h.产品服务')}}</p>
        </div>
        <div class="right-item">
          <h1>{{$t('h.新闻资讯')}}</h1>
          <p @click="goNewsList()">{{$t('h.新闻资讯')}}</p>
        </div>
        <div class="right-item">
          <h1>{{$t('h.关于我们')}}</h1>
          <p @click="goAboutUs">{{$t('h.关于我们')}}</p>
        </div>
      </div>
    </div>
    <!--<div class="footer-bottom">-->
      <!--<div class="footer-menu-item">-->
        <!--{{$t('h.隐私政策')}}-->
      <!--</div>-->
      <!--<div class="footer-menu-item">-->
        <!--{{$t('h.使用条款')}}-->
      <!--</div>-->
      <!--<div class="footer-menu-item">-->
        <!--{{$t('h.辅助功能')}}-->
      <!--</div>-->
      <!--<div class="footer-menu-item">-->
        <!--{{$t('h.现代奴隶制声明')}}-->
      <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>
  export default {
    name: "Footer",
    data() {
      return{
        language: localStorage.getItem("locale")
      }
    },
    methods: {
      onCopy() {
        this.$message.success(`${this.$t('h.复制成功')}`);
      },
      /**
       * 跳转到特种车辆列表页面
       */
      goTeZhongList() {
        this.$router.push("/tezhongList")
      },
      /**
       * 跳转到发电设备列表
       */
      goXiandianList() {
        this.$router.push('/xiandianList')
      },
      /**
       * 跳转到产品中心页面
       */
      goProductCenter() {
        this.$router.push('/productCenter')
      },
      /**
       * 跳转到服务支持页面
       */
      goServiceSupport(scroll) {
        // this.$router.push('/serviceSupport')
        this.$router.push({
          name: "serviceSupport",
          params: {
            scroll: scroll
          }
        })
      },
      /**
       * 跳转到关于我们页面
       */
      goAboutUs() {
        this.$router.push('/aboutUs')
      },
      /**
       * 跳转到联络我们页面
       */
      goContactUs() {
        this.$router.push('/contactUs')
      },
      goNewsList() {
        this.$router.push('/newsList')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .footer-box {
    width: 100%;
    height: 100%;
    background-color: #333;
    background-image: url("../../assets/img/home/home8.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 20px 10px 10px 120px;
    color: #fff;
    display: flex;
    flex-direction: column;

    .footer-top {
      width: 100%;
      flex: 1;
      display: flex;
      justify-content: space-between;


      .footer-left {
        display: flex;
        flex-direction: column;
        font-size: 22px;

        .logo-img {
          width: 360px;
          margin-bottom: 20px;
        }

        .logo-text {
          color: #ffffff;
          font-size: 22px;
          margin-top: 16px;
        }

        .email {
          cursor: pointer;
          color: #ffffff;
          margin-top: 10px;
        }

        h1 {
          font-size: 46px;
          margin-bottom: 40px;
          cursor: pointer;
        }

        a {
          text-decoration: none;
        }

        p + p {
          margin-top: 10px;
        }

        .submit-btn-box {
          margin-top: 50px;

          .submit-btn {
            font-size: 20px;
            padding: 12px 30px;
            display: inline-block;
            background-color: #fff;
            border-radius: 8px;
            color: #333;
            cursor: pointer;
            font-weight: bold;
          }
          /*.submit-btn:hover {*/
            /*color: #3EC163;*/
            /*background-color: #ffffff;*/
          /*}*/
        }
      }
      .footer-right {
        display: flex;
        justify-content: space-between;

        .right-item {
          width: 320px;
          min-height: 50px;

          h1 {
            font-size: 36px;
            font-weight: normal;
            margin-bottom: 40px;
          }

          p {
            font-size: 22px;
            cursor: pointer;
          }
          p:hover {
            color: #3fa600;
          }
          p + p {
            margin-top: 20px;
          }
        }

        .width200 {
        }
      }
    }

    .footer-bottom {
      width: 100%;
      height: 50px;
      border-top: 1px solid #fff;
      display: flex;
      align-items: center;

      .footer-menu-item {
        cursor: pointer;
      }
      .footer-menu-item:hover {
        color: #3fa600;
      }
      .footer-menu-item + .footer-menu-item {
        margin-left: 40px;
      }
    }
  }

  .arPadding {
    padding: 20px 120px 10px 120px;
  }
</style>
